<template>
    <div class="registration-container col-12 mt-3">
        <div class=" pb-5">
            <div class="row ">
                <div class="col-12 ">
                    <b-form class="auth-form" @submit.prevent="Login" autocomplete="off">

                        <div class="row ">
                            <div class="col-12 mt-3 px-lg-3">
                                <input class="form-control" required v-model="payload.user_login"
                                       aria-label="user-login">
                                <div class="placeholder">Логин <span>*</span></div>
                            </div>
                            <div class="col-12 mt-3 px-lg-3">
                                <input class="form-control" required type="password" v-model="payload.user_password"
                                       aria-label="user-password">
                                <div class="placeholder">Пароль <span>*</span></div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mt-3 px-lg-3">
                                <input class="form-control email-input" required v-model="payload.user_email"
                                       aria-label="user-email">
                                <div class="placeholder">E-mail адрес <span>*</span></div>
                            </div>
                            <div class="col-12 mt-3 px-lg-3">
                                <the-mask :mask="['+#(###)-###-##-##', '+#(###)-###-##-###']" class="form-control" v-model="payload.user_phone"
                                          required type="text" aria-label="user-phone"></the-mask>
                                <div class="placeholder">Номер телефона <span>*</span></div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mt-3 px-lg-3">
                                <input class="form-control" required v-model="payload.user_name"
                                       aria-label="promo-code">
                                <div class="placeholder">Ваше имя <span>*</span></div>
                            </div>
                            <div class="col-12 mt-3 px-lg-3">
                                <input class="form-control" required v-model="payload.promo_code"
                                       aria-label="promo-code">
                                <div class="placeholder">Промо-код</div>
                            </div>
                        </div>

                        <div class="row mx-0">
                            <div class="col-12">
                                <alert :message="response"></alert>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="row">
                            <div class="col-12 mt-3">
                                <button class="btn auth-button pink-btn w-100 border-0"
                                        @click.prevent="Registration" :disabled="isSubmitting">
                                    Зарегистрироваться
                                </button>
                            </div>
                        </div>

                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Alert from '@/components/Alert'

    export default {
        name: 'Register',
        props:['activeTab'],
        components: {
            Alert
        },
        data() {
            return {
                response: null,
                isSubmitting: false,
                payload: {
                    user_login: '',
                    user_email: '',
                    user_password: '',
                    user_phone: '',
                    promo_code: '',
                    user_currency: 1,
                    user_name: ''
                }
            }
        },
        methods: {
          Registration() {
            this.response = {status: 'loading'}
            this.isSubmitting = true

            if (this.payload.user_login === '' || this.payload.user_email === '' || this.payload.user_password === '' || this.payload.user_name === '') {
              this.isSubmitting = false
              this.response = {status: 'error', msg: 'Заполните все необходимые поля'}
              return
            }

            this.axios.post(
              this.$_config.baseUrl + '/Api/registration',
              this.payload, {
                withCredentials: true
              }
            ).then(({data}) => {
              this.isSubmitting = false
              this.response = data
              if (data.status === 'ok') {
                this.$store.dispatch('fetchUserData')
                this.$store.dispatch('getMainSettings')
              }
            }).catch((err) => {
              if (err) {
                this.isSubmitting = false
                this.response = {status: 'error', msg: err.response.data.errors}
              }
            })
          }
        },
        computed: {
            isAuthorized() {
                return this.$store.getters['userData']
            }
        },
        watch: {
            isAuthorized(newValue) {
                if (newValue) {
                    this.$router.push({name: 'home'})
                }
            },
            activeTab(newVal){
                if(newVal){
                    this.response = null;
                }
            }
        },
        mounted() {
            if (this.isAuthorized) {
                this.$router.push({name: 'home'})
            }
        }
    }
</script>

<style scoped>
.form-control{
    background: #071637;
    border: 2px solid transparent;
    border-radius: 8px;
    color: #fff;
    height: 44px;
    outline: none !important;
    padding: 0 12px;
    touch-action: manipulation;
    width: 100%;
}

</style>
