<template>
    <div class="messages-container pt-2 pt-lg-4 px-0 col-12 mx-auto">

        <div class="row mx-0 mt-4 justify-content-center">
            <button class="path-item mt-2">
                <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
            </button>
            <span class="path-icon mx-2"> » </span>
            <button class="path-item mt-2">Сообщения</button>
        </div>

        <div class="row mx-0 mt-2 justify-content-center">
            <h1 class="text-white mx-2">Сообщения</h1>
        </div>

        <div class="row mx-0 mb-5">
            <div class="col-12 col-md-9 col-lg-12 mx-auto px-0 pt-3">
                <inner-menu :activePage="'messages'"></inner-menu>

                <div class="row mx-0 py-3 msg-container">
                    <div class="col-12 col-md-11 px-2 px-lg-0 py-0 py-lg-4 mx-auto">

                        <b-list-group class="col-12 mx-auto pr-0" v-if="messages.length">
                            <b-list-group-item v-for="(message, idx) in messages" :key="idx"
                                               class="message-item py-4 px-3 px-lg-5 mb-4 text-white border-0">
                                <b-row>
                                    <div class="col-12 px-1 mb-3 mb-lg-2">
                                        <span class="fs-14 font-weight-bold msg-from"> {{message.time}}</span>
                                    </div>
                                    <div class="col-12 message-text-container px-1" v-html="message.message"></div>

                                    <button v-if="!message.viewed"
                                            class="btn markAsReadBtn text-pink position-absolute pr-3"
                                            :ref="'markAsRead['+message.id+']'"
                                            @click.prevent="()=>{markAsRead(message.id)}"
                                            :disabled="markAsReads === message.id">
                                        Отметить как прочитанное
                                    </button>
                                </b-row>
                            </b-list-group-item>
                        </b-list-group>

                        <div class="text-center" v-else>
                            <span class="text-white">У вас пока нет сообщений</span>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-12 col-md-9 mx-auto coins-bg mb-3"></div>
        </div>

    </div>
</template>

<script>
    import InnerMenu from '@/components/InnerMenu'

    export default {
        components: {
            InnerMenu
        },
        data() {
            return {
                markAsReads: null
            }
        },
        computed: {
            messages() {
                if (this.$store.getters['getMessages'] != null) {
                    return Object.values(this.$store.getters['getMessages']).reverse()
                }
                return {}
            },
            userData() {
                return this.$store.getters['userData']
            }
        },
        watch: {
            userData(newValue) {
                if (newValue) {
                    this.$router.push({name: 'home'})
                }
            }
        },
        mounted() {
            if (!this.userData) {
                this.$router.push({name: 'home'})
            }
        },
        methods: {
            markAsRead(id) {
                this.markAsReads = id
                this.axios.get(this.$_config.baseUrl + '/Api/MessageViewed/' + id)
                    .catch(err => {
                        console.log(err)
                    })
                this.markAsReads = null
            }
        }
    }
</script>


<style scoped>
    .msg-container {
        background-color: #09235f;
        min-height: 50px;
    }

    .message-item {
        background-color: #051949;
        border-radius: 7px;
    }

    .message-item:hover {
        box-shadow: 0 0 6px 5px #051949 !important;
    }

    .fs-14 {
        font-size: 14px;
    }

    .msg-from {
        color: white;
        font-size: 16px;
    }

    .markAsReadBtn {
        right: 0;
        bottom: 0;
        box-shadow: none !important;
    }

    .markAsReadBtn:hover {
        color: #ffee98 !important;
    }
</style>
