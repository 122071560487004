<template>
  <div class="withdraw-container pt-2 pt-lg-4 px-0 col-12 mx-auto">

    <div class="row mx-0 mt-4 justify-content-center">
      <button class="path-item mt-2">
        <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
      </button>
      <span class="path-icon mx-2"> » </span>
      <button class="path-item mt-2">Вывод</button>
    </div>

    <div class="row mx-0 mt-2 justify-content-center">
      <h1 class="text-white mx-2">Вывод</h1>
    </div>

    <div class="row mx-0 mb-5">
      <div class="col-12 col-md-9 col-lg-12 mx-auto px-0 pt-3">
        <inner-menu :activePage="'withdraw'"></inner-menu>

        <div class="row mx-0 py-3 withdraw-methods-container">
          <div class="col-12 col-md-9 col-lg-11 px-2 px-lg-0 py-0 py-lg-4 mx-auto">

            <div class="row mx-0 my-2 px-0 px-lg-5">
              <span class="num ml-2 font-weight-bold">1. </span>
              <span class="text-white ml-2 font-weight-bold"> Выберите Способ вывода</span>
            </div>

            <div class="row my-2 mx-0 justify-content-between" v-if="withdrawMethods && withdrawMethods.regular && withdrawMethods.regular.length > 0">
              <div class="col-6 col-md-4 col-lg-3 col-xl-2 mx-auto mt-3 px-2 d-table"
                   v-for="(method, index) in withdrawMethods.regular" :key="index"
                   v-lazy-container="{ selector: 'img',  loading: require('@/../public/images/icons/game-images-loader.svg') }">
                <div class="withdraw-method paymethod-label text-center d-table-cell cursor-pointer vertical-middle p-1"
                     :class="[method.name == selectedMethod.name ? 'selected' : '']"
                     v-on:click="changeMethod(method)">
                  <img :src="$config.baseUrl + method.img"
                       :data-src="$config.baseUrl + method.img"
                       class="withdraw-method-image mw-100 mh-100 h-100" :alt="method.name">
                </div>
              </div>
            </div>

            <div v-else class="row justify-content-center my-3 w-100">
              <i class="fa fa-spinner fa-spin fa-fw my-5"></i>
            </div>
          </div>
        </div>

        <div class="row mx-0 py-3 make-withdraw-container" v-if="userData">
          <div class="col-12 my-2 px-2 px-lg-5">
            <span class="num font-weight-bold">2. </span>
            <span class="text-white ml-1 font-weight-bold">Вывод на {{ selectedMethod.name }}:</span><br>
            <span class="hint-amount text-white">Минимальная сумма выплаты {{ selectedMethod.min_amount }} руб.</span><br>
            <span class="hint-amount text-white">Максимальная сумма выплаты {{ selectedMethod.max_amount }} руб. за один перевод.</span><br>
            <span class="hint-amount text-white" v-if="selectedMethod.time">
              до {{ selectTime(selectedMethod.time) }}
            </span><br>
            <span class="hint-amount text-white"> {{ selectedMethod.add_info }}</span>
          </div>

          <div class="col-12 my-2 px-2 px-lg-5">
            <b-form class="withdraw-form payment-form">
              <div class="row mx-0">
                <div class="clearfix"></div>
                <Alert :message="formResponse"></Alert>
              </div>

              <div class="row mx-0">
                <div class="col-12 col-md-8 col-lg-4 mx-auto px-0">
                  <input class="form-control" :min="selectedMethod.min_amount" :max="selectedMethod.max_amount"
                         id="withdraw-amount"
                         aria-label="withdraw-amount"
                         v-model="payload.amount"
                         type="number"
                         v-on:keyup="keyUp"
                         required placeholder="Сумма в рублях *">
                  <span class="hint-amount" v-if="withdrawinfo" style="margin-top: 20px; color:red">{{
                      withdrawinfotext
                    }}</span>
                </div>


                <div class="col-12 col-md-8 col-lg-4 mx-auto px-0 px-lg-2 my-2 my-lg-0">
                  <input v-mask="getSelectedMethodMask"
                         class="form-control"
                         aria-label="recipient"
                         v-model="payload.recipient"
                         type="text"
                         :placeholder="selectedMethod.requisites_title"

                  >
              <p class="add-info">
                    {{ selectedMethod.add_info }}
              </p>
                </div>
                <div :class="[selectedMethod.additional_info ?
                                'col-12 col-md-8 col-lg-4 mx-auto px-0 mt-3 px-lg-2 my-2 my-lg-0' :
                                'col-12 col-md-8 col-lg-4 mx-auto px-0 mt-3 px-lg-2 my-2 my-lg-0']"
                     v-if="selectedMethod.additional_info && selectedMethod.additional_info.length">
                     <select v-model="payload.bank" class="form-control h-100">
                          <option :value="null" disabled selected>Выбери банк</option>
                          <option v-for="(method, index) in selectedMethod.additional_info"
                          :key="index"
                          :value="method.code">
                          {{ method.name }}
                          </option>
                      </select>  
                </div>
              </div> 
              <div class="row">
                <div class="col-12 tip mt-5 d-flex justify-content-center" v-if="tipData && tipData.use && tipData.use === true ">
                  <div class="tip-block">
                      <div class="text-block">
                          <p>Хочу оставить на чай</p>
                      </div>
                      <div class="tip-type-block">
                          <div class="fin" v-for="(data, index) in tipTypesData" :key="index">
                              <label for="finButton">
                                <img :src="data.src" class="fin-icon tip-type"
                                :class="{ 'active-type': index === activeType }"
                                @click="tipTypeAction(data.value,index)">
                              </label>
                              <div class="text-center">
                                  <span>{{ data.label }}</span>
                              </div>
                              <div class="check-block" >
                                  <svg class="checkmark" width="40" height="40" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ 'active-check': index === activeType }">
                                      <path class="checkmark__check" d="M24 51.6739C25.8629 51.6739 35.5417 62.1638 40.8583 68.0867C42.7759 70.223 46.0818 70.3683 48.1455 68.3727C57.6301 59.2012 81.8373 36 84 36" stroke="#098a1b" stroke-width="10" stroke-linecap="round" />
                                  </svg>
                              </div>
                          </div>
                      </div>
                      <div class="tip-amount-block">
                          <div class="smaylik-amount-block">
                              <div class="amout-smayl" v-for="(data, index) in tipAmountData" :key="index">
                                  <label for="amountBtn10">
                                    <img :src="data.src" class="amount-icon"
                                    @click="tipAmountAction(tipData.amounts[index],index)"
                                    :class="{ 'active-amount': index === activeAmount }"
                                    >
                                  </label>
                              </div> 
                          </div>
                          <div class="samyl-text-block">
                              <span>Выберите сумму для чаевых от {{ tipData.amounts[0] }}  руб․</span>
                          </div>
                          <div>
                              <input  v-model="tipAmount"
                                      v-on:keyup="tipAmountkeyUp"
                                      placeholder="Сумма на чай"
                                      class="form-control input-md rect tip-amount-val">
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="row mx-0 mt-5 ">
                <div class="col-12 text-center">
                  <button class="btn pink-btn col-lg-5 h-100 w-100 py-3 py-lg-0 px-0" type="submit"
                          :disabled="isSubmitting"
                          @click.stop.prevent="openModal()">
                    Вывести деньги
                  </button>
                </div>  
              </div>
            </b-form>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-9 mx-auto coins-bg mb-3"></div>
    </div>


    <b-modal id="confirmWithdrawModal" class="withdraw-modal" size="lg"
             v-model="confirmWithdrawModal"
             :hide-footer="true">

      <div class="col-12 px-0 text-center pt-3">
        <span class="text-white"> {{ hint }} </span>

        <div class="row mx-0 my-3">
          <div class="clearfix"></div>
          <Alert :message="popupMessages"></Alert>
        </div>

        <div class="form-group row mx-0">
          <div class="col-8 mx-auto px-0">
            <button class="btn purple-btn w-50 border-0 py-3" type="submit" @click.prevent="makeWithdraw()">
              Вывести
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Alert from '@/components/Alert'
import InnerMenu from '@/components/InnerMenu'

export default {
  name: 'Withdraw',
  data() {
    return {
      payload: {
        recipient: '',
        amount: '',
        bank: null
      },
      tipType:'',
      tipAmount:'',
      tipTypesData:[
         {src:require('@/../public/images/icons/tips/fin1.png'), value:1,label:'Фин.Отдел'},
         {src:require('@/../public/images/icons/tips/meneg1.png'), value:2,label:'Менеджер'},
      ],
      tipAmountData:[
         {src:require('@/../public/images/icons/tips/10.png')},
         {src:require('@/../public/images/icons/tips/50.png')},
         {src:require('@/../public/images/icons/tips/100.png')},
         {src:require('@/../public/images/icons/tips/no-tip.png')},
      ],
      activeAmount:'',
      activeType:'',
      selectedMethod: {
        name: '',
        mask: '',
        min_amount: 10,
        max_amount: 15000,
        type: '',
        time:'',
        add_info: ''
      },
      isSubmitting: false,
      modalConfirmed: false,
      confirmWithdrawModal: false,
      formResponse: {},
      popupMessages: {},
      hint: '',
      withdrawinfo: false,
      withdrawinfotext: ''
    }
  },
  components: {
    Alert,
    InnerMenu
  },
  computed: {
    getSelectedMethodMask() {
      if(this.selectedMethod.mask != ''){
      return typeof this.selectedMethod.mask === 'string' ? this.selectedMethod.mask : this.selectedMethod.mask.join(',').split(',');
    }
    return false;
    },
    withdrawMethods() {
      return this.$store.getters['getAvilWithdrawMethods']
    },
    userData() {
      return this.$store.getters['userData']
    },
    tipData() {
      return this.$store.getters.getTipData
    },
  },
  methods: {
    changeMethod(method) {
      this.withdrawinfo = false
      if (!this.userData) {
        this.selectedMethod.name = ''
        this.selectedMethod.mask = ''
        this.selectedMethod.type = ''
        this.selectedMethod.add_info = ''
        this.selectedMethod.min_amount = 10
        this.selectedMethod.max_amount = 15000
        this.$router.push({name: 'home'})
        this.$bvModal.show('AuthModal')
      }
      this.isSubmitting = true
      this.tipType = '';
      this.payload.bank = null;
      this.payload.recipient = '';
      this.tipAmount = '';
      this.activeAmount = '',
      this.activeType = '',
      this.selectedMethod = method
      let elem = document.querySelector(".withdraw-form")
      if (elem !== null) {
        let position = elem.getBoundingClientRect().top + document.documentElement.scrollTop
        position = parseInt(position)
        window.scrollTo({
          top: position,
          behavior: "smooth"
        });
      }
      setTimeout(() => {
        let amountInput = document.getElementById("withdraw-amount")
        if (amountInput) {
          amountInput.focus();
        }
      }, 500)
    },
    selectTime(time){
        if(time > 59){
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        let  formatTime = hours + " ч. ";
        if (minutes !== 0) {
          formatTime += minutes + " мин.";
        }
        return formatTime ;
        }else{
          return time + " мин.";
        }
    },
    makeWithdraw() {
      if (!this.userData) {
        return
      }
      this.formResponse = {status: 'loading'}
      this.popupMessages = {status: 'loading'}

      this.isSubmitting = true
      this.axios.post(
          this.$_config.baseUrl + '/Api/newWithdraw',
          {
            withdraw_amount: this.payload.amount,
            withdraw_requisites: this.payload.recipient,
            method: this.selectedMethod.type,
            bank: this.payload.bank,
            tipType:this.tipType,
            tipAmount:this.tipAmount
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
      ).then(({data}) => {
        if (data.status === 'ok') {
          this.popupMessages = data
          this.formResponse = {}
          this.payload.amount = null
          this.payload.recipient = null
          this.payload.bank = null
          this.activeAmount = '',
          this.activeType = '',
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'confirmWithdrawModal')
          }, 4000)
        } else {
          this.confirmWithdrawModal = false
          this.formResponse = data
        }
        this.isSubmitting = false
      }).catch(err => {
        console.log(err)
      })
      return true
    },
    openModal() {
      this.formResponse = {}
      this.popupMessages = {}

      if (!this.userData) {
        this.formResponse = {status: 'error', msg: "Unauthorized"}
        setTimeout(() => {
          this.$router.push({name: 'home'})
          this.$bvModal.show('AuthModal')
        }, 500)
        return false
      }

      if (!this.selectedMethod.name) {
        this.formResponse = {status: 'error', msg: "Пожалуйста выберите платежную систему"}
        return false
      }

      if (!this.payload.amount || this.payload.amount < this.selectedMethod.min_amount
          || this.payload.amount > this.selectedMethod.max_amount) {
        this.formResponse = {status: 'error', msg: "Пожалуйста, укажите правильую сумму"}
        return false
      }

      if (!this.payload.recipient) {
        this.formResponse = {status: 'error', msg: "Пожалуйста, заполните все поля"}
        return false
      }

      if (this.selectedMethod.type == "auris_ALL") {
        var regs = /^[A-Za-z0-9а-яА-Я]{3,30}$/
        if (!regs.test(this.payload.bank)) {
          this.formResponse = {status: 'error', msg: 'Название банка - неправильный формат'}
          return false
        }
      }

      this.confirmWithdrawModal = true
      this.hint = 'Подтвердите вывод на ' + this.payload.recipient + '. На сумму ' + this.payload.amount + 'Руб.'
    },
    keyUp(evt) {
      this.withdrawinfo = false
      if (this.selectedMethod.type == "visacardpercent" && evt.target.value >= this.selectedMethod.min_amount) {
        this.withdrawinfo = true
        this.withdrawinfotext = "Поступят " + (evt.target.value - 50) + " руб, 50 руб комиссия"
      }
    },
    tipAmountkeyUp(){
      this.isSubmitting = false
    },
    tipTypeAction(type,index) {
      this.tipType = type
      this.activeType = index
    },
   
    tipAmountAction(amount,index){
      this.isSubmitting = false
      this.tipAmount = amount
      this.activeAmount = index
    }
  },
  mounted() {
    if (this.userData) {
      setTimeout(() => {
        let withdrawMethod = document.querySelector('.withdraw-method')
        if (withdrawMethod) {
          withdrawMethod.click();
        }
      }, 500)
    }
  }
}
</script>


<style scoped>
.paymethod-label {
  background-color: #fefefe;
  border: 3px solid #ffffff;
  border-radius: 5px;
  height: 90px;
  width: 95%;
}
.paymethod-label img{
  object-fit: contain;
}
.hint-amount {
  font-size: 14px;
}

.selected {
  border: 3px solid #0ce8ae;
}

.num {
  color: #0ce8ae;
}
.tip{
    color: #ffffff;
}
.tip-block{
    width: 300px;
}
.fin-icon,
.mange-icon{
    cursor: pointer;
    transition: fill 0.3s;
    border-radius: 50%;
    object-fit: contain;
}
.samyl-text-block{
    text-align: center;
}
.text-block{
    margin-top: 20px;
    font-size: 20px;
}
.mange span{
    margin-right: 20px;
}
.tip-type-block{
    height: 120px;
    display: flex;
    justify-content: space-around;
}
.tip-type{
  width: 70px;
  height: 70px;
  transition: width 0.5s, height 0.5s;

}
.active-type{
  width: 80px;
  height: 80px;
}
.smaylik-amount-block{
    height: 70px;
    display: flex;
    justify-content: space-between;
}
.text-block p{
    text-align: center;
}
.amount-icon{
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    transition: width 0.5s, height 0.5s;
}
.add-info{
  color: #ffffff;
  font-weight: bold;
  margin-top: 20px;
 }
.active-amount{
    width: 60px;
    height: 60px;
}
.tip-amount-block{
    margin-top: 30px;
}

.tip-amount-val{
    margin-top: 30px;
    margin-bottom: 30px;
}
.check-block{
    display: flex;
    justify-content: center;
}
.checkmark{
  display: none;
}
.active-check{
  display: block;
}
.checkmark__check {
    stroke-dasharray: 412;
    stroke-dashoffset: 412;
    animation: anim 1s ease forwards 0.3s;
}

@keyframes anim {
    to {
        stroke-dashoffset: 0;
    }
}
</style>


