<template>
    <div class="games-container pt-4 pt-lg-5 pb-0 pb-lg-5" v-if="selectedTab && games">
        <div class="row mx-0">
            <div class="col-12  d-none d-xl-block">
                <div class="row mx-0 mb-3 py-2 game-categories-container ">
                    <div class="col-2 mb-2 d-none d-xl-block  category-item cursor-pointer "
                         v-for="(tab, idx, index) in games"
                         :key="idx"
                         @click.prevent="changeTab(idx)"
                         :class="[idx===currentTabIndex ? 'active' : '']">
                        <div class="row py-2 mx-0">
                            <div class="col-3 pr-0">
                                <img :src="getIconForTab(index)" :alt="tab.name"><br>
                            </div>
                            <div class="col-9 px-0">
                                <span class="">{{tab.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-xl-12 games">
                <!-- for mobile and tablets START-->
                <div class="row search-desctop mx-0 text-white">
                    <b-form class="search-form w-45" autocomplete="off">
                        <b-form-group class="mb-0">
                            <b-form-input v-model="gamesSearchText" placeholder="Поиск игр" name="search-input"
                                          aria-label="game search"
                                          style="font-family:Arial, FontAwesome"></b-form-input>
                            <b-button class="removeButton position-absolute border-0" v-if="gamesSearchText"
                                @click.stop.prevent="clearSearch">
                                <i class="fa fa-remove"></i>
                            </b-button>
                        </b-form-group>
                    </b-form>
                </div>

                <div class="row d-xl-none mx-0 text-white">
                    <div class="col-12 px-0">
                        <b-button v-b-toggle.category-collapse class="w-100 category-collapse mt-3 px-0 text-white">
                            Категории
                            <img :src="arrow" class="menu-arrow" alt="volga slot - menu arrow">
                        </b-button>
                    </div>
                    <b-collapse id="category-collapse" ref="categoryCollapse"
                                @show="()=>{showProvidersCollapse = false}" v-model="showCategoriesCollapse" class="w-100 mx-0">
                        <b-card class="categories-card">
                            <div class="row mx-0 mb-3 py-2 game-categories-container-mobile">
                                <div class="col-12 col-sm-5 py-2 mx-auto category-item cursor-pointer"
                                    v-for="(tab, idx, index) in games"
                                    :id="idx"
                                    :key="idx"
                                    :class="[idx===currentTabIndex ? 'active' : '']"
                                    @click.prevent="changeTab(idx)">

                                    <div class="row mx-0">
                                        <div class="col-1 col-sm-2 px-0">
                                            <img :src="getIconForTab(index)" :alt="tab.name">
                                        </div>
                                        <div class="col-10 mx-auto px-0">
                                            <span class="">{{tab.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </b-collapse>
                </div>

                <!--  for mobile and tablets END-->
                <b-card no-body class="col-12 px-0 mt-3 mt-xl-0">
                    <b-tabs card vertical
                            class="games-tabs row justify-content-between"
                            id="games-tabs"
                            v-model="currentTab"
                            nav-wrapper-class="w-100"
                            ref="gamesTabs">

                        <GameTabTemplate :tab="selectedTab"></GameTabTemplate>

                        <b-tab title-item-class="search-tab" v-if="typeof selectedTab === 'boolean'"
                               class="col-12 px-0"
                               v-lazy-container="{ selector: 'img', observer: true, loading: require('@/../public/images/icons/game-images-loader.svg') }">
                            <h3 class="text-center text-white" v-if="!foundedGames.length">
                                <i class="fa fa-spinner fa-spin fa-fw my-5" v-if="searchLoading"></i>
                               <span v-else> По запросу ничего не найдено</span>
                            </h3>
                            <div class="row justify-content-around games-list-row no-gutters px-0" v-else
                                 v-lazy-container="{ selector: 'img', observer: true, loading: require('@/../public/images/icons/game-images-loader.svg') }"
                                 >
                                <div class="col-6 col-md-2 game-item" v-for="(game, i) in foundedGames"
                                     :key="'founded'+i">
                                    <GameItemPreview :game="game"></GameItemPreview>
                                </div>
                            </div>
                        </b-tab>

                    </b-tabs>
                </b-card>
            </div>
        </div>
        <div class="row mx-0  mt-5">
            <winner-list></winner-list>
        </div>
    </div>
    <div v-else class="row justify-content-center my-5 w-100">
        <i class="fa fa-spinner fa-spin fa-fw my-5"></i>
    </div>
</template>

<script>
    import GameItemPreview from '@/components/games/GameItemPreview'
    import GameTabTemplate from "./GameTabTemplate"
    import WinnerList from "../home/WinnerList";

    export default {
        data() {
            return {
                showProvidersCollapse: false,
                showCategoriesCollapse: false,
                selectedProviders: [],
                selectedTab: null,
                searchLoading:true,
                searchTimeoutId: null,
                foundedGames: [],
                showTabsCount: 5,
                currentTabIndex: '',
                searchTabActive:'',
                currentTab: 0,
                currentTabName: 'Популярные',
                gamesSearchText: '',
                companyName: '',
                arrow: require('@/../public/images/icons/menu-icons/arrow.png'),
                tabIcons: [
                    require('@/../public/images/icons/category-icons/all.png'),
                    require('@/../public/images/icons/category-icons/cards.png'),
                    require('@/../public/images/icons/category-icons/jackpot.png'),
                    require('@/../public/images/icons/category-icons/like.png'),
                    require('@/../public/images/icons/category-icons/new.png'),
                    require('@/../public/images/icons/category-icons/other.png'),
                    require('@/../public/images/icons/category-icons/roulette.png'),
                    require('@/../public/images/icons/category-icons/slot.png'),
                    require('@/../public/images/icons/category-icons/sport.png'),
                    require('@/../public/images/icons/category-icons/top.png')
                ],
            }
        },
        components: {
            GameItemPreview,
            GameTabTemplate,
            // Carousel,
            // Slide,
            WinnerList
        },
        methods: {
            clearSearch(){
                this.gamesSearchText = '';
            },
            searchGamev2() {
                if (this.gamesSearchText === '') {
                    return true

                }
                this.searchLoading = true;
                this.$store.dispatch('fetchSearchData',this.gamesSearchText)
                this.foundedGames = []
                this.setTimeout(()=>{
                const searchGame = this.searchV2;
                this.foundedGames = searchGame
                let gamesFound = false
                if (searchGame.length > 0) {
                    gamesFound = true
                    this.selectedTab = {
                    games: searchGame,
                    gamesFound: gamesFound,
                    searchText: this.gamesSearchText
                }
                }else{
                    this.selectedTab = true; 
                    setTimeout(()=>{
                        this.searchLoading = false
                    },100)
                }
                return true
                },200) 
            },
            changeTab (tabId) {
                this.searchTabActive = tabId;

                if (this.games[tabId].games.length == 0) {
                    this.$store.dispatch('fetchGamesByProvidersv2', tabId)
                    this.currentTabIndex = tabId
                    return this.searchGamev2()
                } else {
                    this.currentTabIndex = tabId
                    return this.searchGamev2()
                } 
            },
            setTimeout(callback, timeout) {
                setTimeout(callback, timeout)
            },
            getIconForTab(idx) {
                if (idx > this.tabIcons.length) {
                    idx %= this.tabIcons.length
                }
                return this.tabIcons[idx] ? this.tabIcons[idx] : this.tabIcons[0]
            },
        },
        computed: {
            searchV2(){
                return this.$store.getters['getSearchV2']
            },
            firstTab() {
                if (this.games) {
                    return this.games[(Object.keys(this.games)[0])]
                }
                return false
            },
            games () {
            const allGames = this.$store.getters.games
            let availableGames = null
            if (!allGames) {
              return null
            }
            availableGames = {}
            for (const tabKey in allGames) {
              const tabOptions = allGames[tabKey].opts

              if (
                  (tabOptions.hasOwnProperty('android') && !tabOptions.android && this.$_config.isAndroid()) ||
                  (tabOptions.hasOwnProperty('ios') && !tabOptions.ios && this.$_config.isIos()) ||
                  (tabOptions.hasOwnProperty('desktop') && !tabOptions.desktop && !this.$_config.environment.mobile)
              ) {
                continue
              }

              availableGames[tabKey] = {
                hasNewIcon: allGames[tabKey].hasNewIcon ? allGames[tabKey].hasNewIcon : false,
                icon: allGames[tabKey].icon,
                name: allGames[tabKey].name,
                games: [],
                auth: !!allGames[tabKey].hasOwnProperty('need_auth'),
                role: allGames[tabKey].role ? allGames[tabKey].role : null,
                openAsFirstTab: allGames[tabKey].openAsFirstTab ? allGames[tabKey].openAsFirstTab : false,
              }

              for (const i in allGames[tabKey].games) {
                if (!allGames[tabKey].games.hasOwnProperty(i)) {
                  continue
                }
                const game = allGames[tabKey].games[i]
                if (
                    (game.hasOwnProperty('android') && !game.android && this.$_config.isAndroid()) ||
                    (game.hasOwnProperty('ios') && !game.ios && this.$_config.isIos()) ||
                    (game.hasOwnProperty('desktop') && !game.desktop && !this.$_config.environment.mobile) ||
                    (game.picture === '')
                ) {
                  continue
                }

                availableGames[tabKey].games.push(game)
              }
            }
                return availableGames
            },
            isMobile() {
                return this.$_config.environment.mobile
            },
            tabsCount() {
                try {
                    return Object.keys(this.games).length
                } catch (e) {
                    return 0
                }
            },
        },
        watch: {
            gamesSearchText (newVal, oldValue) {
                if (newVal !== '' && newVal.length > 2) {
                    clearTimeout(this.searchTimeoutId)
                    this.searchTimeoutId = setTimeout(() => {
                    this.selectedTab = true
                    this.currentTabIndex = '';
                    this.searchGamev2()
                    }, 800)
                } else if (oldValue !== '' && newVal.length > 2) {
                    clearTimeout(this.searchTimeoutId)
                    this.searchTimeoutId = setTimeout(() => {
                    this.selectedTab = true;
                    this.currentTabIndex = '';
                    this.searchGamev2()
                    }, 800)
                }
            },
            games() {
                if (this.games) {
                    this.selectedTab = this.games[(Object.keys(this.games)[0])]
                }
            }
        },
        updated () {
            if (this.games && this.gamesSearchText === '' || this.games && this.gamesSearchText.length < 3) {
                if (typeof this.currentTabIndex === 'undefined' || this.currentTabIndex === '') {
                    if(this.searchTabActive !== ''){
                        this.currentTabIndex = this.searchTabActive;
                    }else{
                        this.currentTabIndex = Object.keys(this.games)[0]
                    }
                }
                this.selectedTab = this.games[this.currentTabIndex]
            }
        },
        mounted() {
            this.$store.dispatch('fetchGames')
            this.$store.dispatch('checkForAliveGame')
            if (this.games) {
              this.currentTabIndex = Object.keys(this.games)[0]
                this.selectedTab = this.games[this.currentTabIndex]
            }
        }
    }
</script>

<style scoped>
    .carousel-container {
        background-color: #190c25;
    }

    .category-item .row:hover {
        border-bottom:2px solid #0ce8ae;
    }

    .w-80 {
        width: 80%;
    }

    .search-desctop{
        margin-bottom: 30px;
        display: flex;
        justify-content: end;
    }
    .w-45{
        width: 40%;
    }
    .stat-game-img {
        border-radius: 7px;
    }

    .game-categories-container-mobile,
    .game-categories-container,
    .stat-game-container {
        background-color: #0483c2;
        color: white;
        border-radius: 7px;
        margin-bottom: 15px;
    }

    .game-providers-container-mobile,
    .game-categories-container-mobile {
        max-height: 400px;
        overflow-y: scroll;
    }

    .game-providers-container-mobile::-webkit-scrollbar,
    .game-categories-container-mobile::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
    }
    .game-categories-container{
    background-color: transparent;
    }
    .game-providers-container-mobile::-webkit-scrollbar-thumb,
    .game-categories-container-mobile::-webkit-scrollbar-thumb {
        background-color: #0ce8ae;
        border-radius: 7px;
    }

    .stat-game-container span {
        font-size: 20px;
        text-transform: uppercase;
    }

    .stat-game-title h6 {
        text-transform: uppercase;
    }


    .category-item.active .row,
    .provider-logo:hover,
    .provider-logo.active{
        border-bottom: solid 2px #0ce8ae;
    }

    .provider-logo img {
        height: 95px;
        width: 135px;
        padding: 5px 7px;
        object-fit: contain;
    }

    .search-form input {
        border: none;
        border-radius: 7px;
        padding: 0 30px;
        background-color: aliceblue;
        color: #505255;
        box-shadow: none !important;
        background-image: url("../../../public/images/icons/search.svg");
        background-position: 10px center;
        background-repeat: no-repeat;
        text-indent: 10px;
    }

    .search-form input::placeholder {
        color: #626366;
        font-weight: 600;
    }

    .removeButton {
        top: 0;
        right: 15px;
        color: #454749!important;
        box-shadow: none !important;
        background-color: transparent !important;
        margin-top: 1px;
    }

    .games .card {
        background: transparent;
        border: none;
    }

    @media only screen and (max-width: 1200px) {
        .stat-game-container h6 {
            font-size: 14px;
            text-transform: none;
        }

        .provider-logo img {
            height: 65px;
            max-width: 100%;
        }
    }
    @media only screen and (max-width: 767px) {
        .w-45{
        width: 100%;
    }
    }
</style>

<style>
    .providers-card .card-body,
    .categories-card .card-body {
        padding: 0;
        padding-top: 10px;
    }

    .category-collapse,
    .provider-collapse {
        background-color:  #0483c2!important;
        border: none !important;
        box-shadow: none !important;
        font-weight: 600;
    }

    .VueCarousel-dot-container {
        margin-top: 0 !important;
    }

    .VueCarousel-dot {
        outline: none !important;
    }

    .search-tab .nav-link {
        background: transparent !important;
        border: none !important;
    }

    .games-tabs .card-header {
        background: transparent !important;
        padding: 0;
    }

    .menu-arrow {
        transition: 1s;
    }

    .collapsed .menu-arrow {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }
</style>
