<template>
    <div class="col-12 col-lg-10 mx-auto py-4">
      <div class="row w-100 mx-0 mt-lg-2 mb-3 justify-content-center">
        <button class="path-item mt-3">
          <router-link :to="{ name: 'home' }" class="text-white"> Главная </router-link>
        </button>
        <span class="path-icon mx-2 mt-1"> » </span>
        <button class="path-item mt-3">Уровни игроков</button>
      </div>
        <div class="col-12 px-0" v-if="levels">
            <table class="table table-stripped table-info text-center levels-table">
                <thead>
                    <tr class="level-block">
                        <th class="border-top-0 levels-color">Уровень</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(level, idx) in levels" :key="idx" :class="{'active':userLevel === level.level}">
                        <td>
                            <img :src="level.icon" alt="" class="level-img">
                          <span>{{level.title}}</span>
                            <div class="level-progressbar" v-if="uDepSum && userLevel === level.level">
                                <b-progress :value="uDepSum" :max="level.max_border" animated>
                                </b-progress>
                                <span class="hint-text">{{reachProgress(level.max_border).levelPartReachedUnRounded}} Очков из 100</span>
                            </div>
                        </td>
                        <td class="d-lg-table-cell" v-html="level.bonuses"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    name: "UserLevels",
    computed: {
      levels () {
        return this.$store.getters.clientsLevel
      },
      uDepSum () {
        return this.$store.getters.uDepSum
      },
      userLevel () {
        const userData = this.$store.getters.userData
        if(userData) {
          return userData.level
        }
        return false
      },

    },
    methods: {
      showLevelInfo(levelId) {
        return this.$root.$emit('bv::show::modal', 'levelInfoModal_'+levelId)
      },
      reachProgress ($maxBorder) {
        let levelPartReached = (this.uDepSum/$maxBorder)*100;
        const levelPartReachedUnRounded = (levelPartReached).toFixed(4);
        levelPartReached = Math.round(levelPartReached);
        return {
          levelPartReached, levelPartReachedUnRounded: parseFloat(levelPartReachedUnRounded)
        }
      }
    }
}
</script>

<style scoped>
    .levels-color{
      color:  #08b287;
    }
    .level-block{
      display: flex;
      justify-content: space-between;
      padding:0 50px;
    }
    td{
        vertical-align:middle !important;
        position: relative;
    }
    .level-img{
        width: 160px;
    }
    tr.active{
        box-shadow: 0 0 15px 5px #08b287;
    }
    tr.active td:nth-child(2):before{
        display: block;
        content: 'Ваш Уровень';
        font-size: 15px;
        text-decoration: underline;
        color: white;
    }
    @media (max-width: 450px){
      .level-img{
        width: 80px;
    }
    .levels-table thead tr th{
      font-size: 15px;
    }
    .levels-table td{
      font-size: 13px;
    }
    }
</style>
