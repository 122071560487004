<template>
    <b-modal id="confirmEmailModal" size="lg" :hide-footer="true" v-if="userData" @close="closeModal">

        <div class="modal-title text-center mb-3">
            <span class="text-white mb-4">Подтверждение email</span><br>
            <span class="email mb-4">{{userData.email}}</span>
        </div>

        <form @submit.prevent="confirmEmail" class="email-confirmation-form modal-form mt-4" autocomplete="off"
              v-if="!successedVerification">

            <Alert :message="emailSendResponse"></Alert>

            <b-form-group>
                <div class="row mx-0">
                    <div class="col-12 px-0">
                        <b-form-input v-model="email" required></b-form-input>
                    </div>
                </div>
            </b-form-group>
            <b-form-group>
                <div class="row mx-0">
                    <div class="col-4 col-lg-2 px-0">
                        <captcha ref="email_confirmation" :captcha_key="'email_confirmation'"></captcha>
                    </div>
                    <div class="col-8 col-lg-6">
                        <b-form-input v-model="captcha" required></b-form-input>
                    </div>
                    <div class="col-8 col-lg-4 mx-auto mt-3 mt-lg-0 px-0">
                        <button name="submit" type="submit" class="btn pink-btn px-3 w-100"
                                :disabled="isEmailSending" @click.stop.prevent="sendConfirmationEmail">
                            Отправить письмо
                        </button>
                    </div>

                </div>
            </b-form-group>
        </form>

        <div class="row mx-0 my-5" v-else>
            <span class="mx-auto text-white text-center">
               Перейдите по ссылке из письма, которое мы отправили на ваш email
            </span>
        </div>
    </b-modal>
</template>

<script>
  import captcha from '@/components/Captcha'
  import Alert from '@/components/Alert'

  export default {
    props: ['userData'],
    name: 'ConfirmEmail',
    data() {
      return {
        successedVerification: false,
        emailSendResponse: null,
        isEmailSending: false,
        captcha: '',
        email: null
      }
    },
    components: {
      Alert,
      captcha
    },
    methods: {
      closeModal() {
        this.emailSendResponse = null
      },
      sendConfirmationEmail() {
        this.emailSendResponse = {status: 'loading'}
        this.isEmailSending = true
        let queryString = `email=${this.email}&email_confirmation_captcha=${this.captcha}`
        console.log(queryString)
        this.axios.post(
          this.$_config.baseUrl + '/Api/EmailConfirmRequest/', queryString, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        ).then(({data}) => {
          this.emailSendResponse = data
          this.captcha = ''
          if (data.status !== "error") {
            this.successedVerification = true
            this.$store.dispatch('fetchUserData')
            setTimeout(() => {
              this.successedVerification = false
              this.emailSendResponse = null
              this.$root.$emit('bv::hide::modal', 'confirmEmailModal')
            }, 5000)
          }
        }).catch(() => {
          this.emailSendResponse = {
            status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
          }
        })
        this.isEmailSending = false
        this.$refs.email_confirmation.updateCaptcha()
      }
    },
    mounted() {
      this.email = this.userData.email
    }
  }
</script>


<style scoped>
    .modal-title span {
        font-size: 30px;
        font-weight: 600;
        word-break: break-all;
    }

    .modal-title .email {
        color: #0ce8ae;
    }

    @media only screen and (max-width: 767px) {
        .modal-title span {
            font-size: 22px;
            font-weight: 500;
        }
    }
</style>
