<template>
    <div class="col-12 col-md mx-0">
        <div class="winners-list-container">
            <div class="col-12 px-0 mx-0 winners-ul-container overflow-hidden">
                <ul class="winners w-100 list-unstyled" ref="winnersList" v-if="winners">
                    <li class="winner-item  py-2 my-2" v-for="(winner, idx) in winners" :key="idx">
                        <div class="row winner-item-child mx-0 cursor-pointer"  @click="gotoGameView(winner)">
                            <div class="col-4 px-0 d-table">
                                <div class="d-table-cell vertical-middle game-img-container pr-1"
                                     v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">
                                    <img :data-src="winner.preview" :src="winner.preview"
                                         class=" mr-lg-1" alt="volga slote - games">
                                </div>
                            </div>
                            <div class="col-4 px-0 d-table text-block">
                                <div class="vertical-middle text-center">
                                    <span class="user text-white">{{winner.login}}</span>
                                </div>
                            </div>
                            <div class="col-4 px-0 d-table text-block">
                                <div class="vertical-middle  ">
                                    <span class="amount d-block float-right text-white">{{winner.win}} Руб</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div v-else class="row justify-content-center w-100">
                    <i class="fa fa-spinner fa-spin fa-fw my-5"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                avilPositions: 0,
                slideIntarvalId: false
            }
        },
        computed: {
            winners() {
                return this.$store.getters['getWinnersList']
            }
        },
        methods: {
            gotoGameView(winner) {
                this.$router.push({
                    name: 'TheGame',
                    params: {
                        gameName: winner.game_name,
                        gameId: winner.game,
                        provider: winner.provider,
                        restoring: false
                    }
                })
            },
            initSlide() {
            if (!this.winners) {
                return true;
            }

            this.avilPositions = parseInt(this.winners.length / 4);
            this.winners.length % 4 !== 0 ? this.avilPositions++ : '';

            clearInterval(this.slideIntarvalId);

            this.slideIntarvalId = setInterval(() => {
                if (this.$refs['winnersList']) {
                    let ct = this.$refs['winnersList'].style.transform.replace(/[^\d.-]/g, '');
                    ct = parseInt(ct) || 0;
                    if (Math.abs(ct - 5) >= this.$refs['winnersList'].clientWidth) {
                        ct = 0;
                    }
                    this.$refs['winnersList'].style.transform = 'translateX(' + (ct - 46) + 'px' + ')';
                }
            }, 1100);
        }
        },
        mounted() {
          this.initSlide()
        },
        beforeDestroy() {
            clearInterval(this.slideIntarvalId)
        }
    }
</script>


<style scoped>
    .winners-ul-container{
        border-bottom: 1px #2f3133 solid;
    }
    .winners{
        display: flex;
    }
    .text-block{
        font-size: 14px;
        margin: auto;
        padding-right: 10px;
    }
    .winner-item-child{
        width: 200px;
        padding-right: 10px;
    }
    .game-img-container {
        width: 50px;
        height: 50px;
    }
    .game-img-container img{
        width: 50px;
        height: 100%;
        object-fit: cover;
    }

    .winners-title {
        background-color: #2dc53a;
        z-index: 2;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    .winners-ul-container {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        max-height: 370px;
        margin-top: -10px;
    }

    .winners-list-container ul {
        transition: all 1.5s;
    }

    @media only screen and (max-width: 767px) {
        .amount,
        .user {
            font-size: 12px;
        }

        .game-title {
            color: white;
            font-size: 12px;
        }
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
        .game-title,
        .amount,
        .user {
            font-size: 12px;
        }
    }
</style>
