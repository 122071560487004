<template>
    <div class="col-12 px-0" v-if="tab && tab.games && winners">
        <div class="w-100" v-if="tab.games.length">
            <div class="row w-100 mx-0">
                <div class="col-12 px-0">
                    <div class="row justify-content-start  games-list-row no-gutters mx-0">
                        <div class="game-item-col col-6 col-sm-4 col-md-2" v-for="(game, i) in tab.games" :key="'idx'+i"
                             v-if="i<12">
                            <GameItemPreview :game="game"></GameItemPreview>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row games-list-row no-gutters mx-0 mt-1">
                <div class="game-item-col col-6 col-sm-4 col-md-2" v-for="(game, i) in tab.games" :key="'idx'+i" v-if="i>12">
                    <GameItemPreview :game="game"></GameItemPreview>
                </div>
            </div>
        </div>
        <div class="w-100 text-center my-5" v-else>
            <h4 class="text-white">В этой категории нет игр</h4>
        </div>
    </div>
</template>

<script>
    import GameItemPreview from "./GameItemPreview";

    export default {
        props: ['tab'],
        name: "GameTabTemplate",
        components: {
            GameItemPreview,
        },
        computed: {
            winners() {
                return this.$store.getters['getWinnersList']
            }
        }
    }
</script>

<style scoped>
    .game-item-col {
        text-align: -webkit-center;
    }
</style>
