<template>
  <div class="deposit-container pt-2 pt-lg-4 px-0 col-12 mx-auto">
    <div class="row mx-0 mt-4 justify-content-center">
      <button class="path-item mt-2">
        <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
      </button>
      <span class="path-icon mx-2"> » </span>
      <button class="path-item mt-2">Баланс</button>
    </div>

    <div class="row mx-0 mt-2 justify-content-center">
      <h1 class="text-white mx-2">Контоль балансов</h1>
    </div>
    <div class="row mx-0 mb-5">
      <div class="col-12 col-md-9 mx-auto px-0 pt-3">
        <inner-menu :activePage="'balanceTransfer'"></inner-menu>
        <div class="balance-transfer-container col-12 mx-auto row mx-0 py-3">
          <div class="col-12 col-lg-10 mx-auto py-3 px-0">
            <div class="col-12 row mx-0 justify-content-around mb-4">
              <div class="col-12 col-lg-5 alert alert-secondary mb-3 mb-lg-0">Баланс казино: {{casinoBalance}} {{currencyName}}</div>
              <div class="col-12 col-lg-5 alert alert-secondary mb-3 mb-lg-0">Баланс кошелька: {{walletBalance}} {{currencyName}}</div>
            </div>
            <div class="col-12 row mx-0 justify-content-between mb-4 px-0">
              <div class="col-12 col-lg-4 py-2">
                <b-form-select v-model="payload.balance_from" class="text-black" @change="selectBalance">
                  <option :value="null" disabled>Выберите тип трансфера</option>
                  <option value="balance">С баланса казино на кошелек</option>
                  <option value="main_balance">С кошелька на баланс казино</option>
                </b-form-select>
              </div>
              <div class="col-12 col-lg-8">
                  <alert :message="infoMessage"></alert>
              </div>
            </div>
            <div class="col-12 row mx-0 justify-content-between px-0" v-if="showAmountArea">
              <b-form-group class="mb-2 mb-sm-4 col-12 col-lg-4 px-0">
                <div class="col-12 d-inline-block">
                  <input
                      v-model="payload.transferAmount"
                      type="text"
                      required
                      id="balanceTransferAmount"
                      class="form-control main-input"
                      placeholder="Введите сумму перевода"
                  >
                </div>
              </b-form-group>
              <b-form-group class="mb-3 mt-1 col-12 col-lg-8 px-0" v-if="allowTransfer">
                <div class="col-12 d-inline-block text-center text-lg-left">
                  <button class="btn pink-btn text-white"
                          :disabled="isSubmitting"
                          @click.stop.prevent="transferBalance">
                    Перевести</button>
                </div>
              </b-form-group>
              </div>
            <div class="col-12">
                <alert :message="response"></alert>
            </div>
            <div class="col-12" v-if="transfersOnHoldAmount">
                <div class="alert alert-info mb-2 text-lg-left">В ожидании : {{transfersOnHoldAmount}} {{currencyName}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/Alert'
import InnerMenu from '@/components/InnerMenu'

export default {
  name: 'BalanceTransfer',
  data () {
    return {
      walletBalance: 0,
      casinoBalance: 0,
      currencyName: "руб",
      infoMessage: {status: "loading"},
      showAmountArea: false,
      payload : {
        transferAmount : null,
        balance_from : null
      },
      response: null,
      isSubmitting: false,
      allowTransfer: false,
      transfersOnHoldAmount: null
    }
  },
  components: {
    Alert,
    InnerMenu,
  },
  computed: {
    userData () {
      return this.$store.getters.userData
    }
  },
  async created() {
    await this.$store.dispatch('fetchOnHoldTransfersAmount')
  },
  updated() {
    this.transfersOnHoldAmount = this.$store.getters.getOnHoldTransactionsAmount
  },
  mounted() {
    setTimeout(() => {
      this.transfersOnHoldAmount = this.$store.getters.getOnHoldTransactionsAmount
    }, 1000)
    if (this.userData) {
      this.walletBalance = this.userData.main_balance;
      this.casinoBalance = this.userData.balance;
      this.currencyName = this.userData.currency_name;
      this.infoMessage = {status: "ok", msg: "Выберите баланс трнасфера"}
    }
    if (!this.userData) {
      this.$router.push({name: 'home'})
    }
  },
  methods: {
    selectBalance(value) {
      this.showAmountArea = true;
      this.allowTransfer = true;
      let amount = this.payload.transferAmount ? this.payload.transferAmount +"руб " : "средтсва";
      if (value) {
        if (value === "balance") {
          if (parseFloat(amount) > this.casinoBalance) {
            this.infoMessage = {status: "error"}
            this.allowTransfer = false;

          } else {
            this.infoMessage = {status: "ok"}
          }
          this.infoMessage.msg = `Перевеод ${amount} с баланса казино <span class="transfer_balance_to">на кошелек</span> ( доступно ${this.casinoBalance} руб.) `
        } else if (value === "main_balance") {
          if (parseFloat(amount) > this.walletBalance) {
            this.infoMessage = {status: "error"}
            this.allowTransfer = false;
          } else {
            this.infoMessage = {status: "ok"}
          }
          this.infoMessage.msg = `Перевод ${amount} с кошелка <span class="transfer_balance_to">на баланс казино</span> ( доступно ${this.walletBalance} руб.) `
        }
      }
    },
    transferBalance() {
      this.isSubmitting = true
      this.response = {status: 'loading'}
      let data = {}
      if (!this.payload.transferAmount) {
        this.response = {
          status: 'error', msg: 'Введите сумму'
        }
        this.isSubmitting = false
        return false
      }
      if (!this.payload.balance_from) {
        this.response = {
          status: 'error', msg: 'Выберите баланс трансфера'
        }
        this.isSubmitting = false
        return false
      }
      if (this.payload.transferAmount != parseFloat(this.payload.transferAmount)) {
        this.response = {
          status: 'error', msg: 'Введите правильную сумму'
        }
        this.isSubmitting = false
        return false
      }
      data = {
        balance_from : this.payload.balance_from,
        balance_transfer_amount : this.payload.transferAmount,
      }

      this.axios.post(
          this.$_config.baseUrl + '/Api/TransferBetweenBalances/',
          data
      ).then(({ data }) => {
        if (data.status) {
          this.response = data
          this.isSubmitting = false
          if (data.status && data.status === 'ok') {
            setTimeout(() => {
              this.$store.dispatch('fetchUserData')
            }, 1500)
          }
        } else {
          this.response = {
            status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
          }
        }
      }).catch(() => {
        this.isSubmitting = false
        this.response = {
          status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
        }
      })
    }
  },

  watch :{
    payload : {
      deep: true,
      handler() {
        let type = this.payload.balance_from
        if (type) {
          this.selectBalance(type)
        }
      }
    },
    userData(newVal) {
      if (newVal) {
        this.walletBalance = this.userData.main_balance;
        this.casinoBalance = this.userData.balance;
        this.currencyName = this.userData.currency_name;
        this.infoMessage = {status: "ok", msg: "Выберите баланс трнасфера"}
      }
    },
    transfersOnHoldAmount (newValue) {
      if (newValue) {
          return this.transfersOnHoldAmount = newValue
      }
    }
  }
}
</script>

<style>
  .transfer_balance_to{
    color:#ffffff;
    font-weight: 800;
    text-decoration: underline;
  }
  .balance-transfer-container{
    background-color: #261336
  }
</style>
