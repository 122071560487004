<template>
    <b-modal id="AuthModal" size="lg"
             :hide-footer="true"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             class="p-0 modal"
    >
        <div class="row">
            <div class="col-12 col-lg-4 auth-img d-none d-lg-block">
                <img :src="logo" class="logo-auth mt-5 ml-5">
            </div>
             <div class="col-12 col-lg-8">
                <b-card class="border-0 w-100 mt-3 authmodal-block">
                  <b-tabs card class="change-auth-tab px-0" v-model="showForm">
                     <b-tab title="Регистрация">
                        <Register :activeTab="showForm"/>
                    </b-tab>

                    <b-tab title="Войти">
                       <Login :activeTab="showForm"/>
                   </b-tab>

                 </b-tabs>
                </b-card>
             </div>
        </div>

    </b-modal>
</template>

<script>
    import Login from "../elements/Login";
    import Register from "../elements/Register";

    export default {
        name: 'AuthModal',
        props: ['activeTab'],
        components: {
            Login,
            Register
        },

        data(){
            return{
                showForm:1,
                logo: require('@/../public/images/logos/logo.svg'),
            }
        },

        watch:{
            activeTab(newVal){
                if(newVal || newVal === 0){
                   this.showForm = newVal;
                }
            },
        },
        
    }
</script>

<style>

.authmodal-block{
    background-image: linear-gradient(#0b2458, #071637);
}
.authmodal-block .nav-tabs .nav-item{
    width: 50%;
}
 .nav-tabs .nav-link{
    width: 100%!important;
    background: transparent!important;
    text-align: center!important;
    cursor: pointer!important;
    border: unset!important;
    color: #828f9a;

}
.nav-tabs .nav-link:hover{
    color: white;  
}
.nav-tabs .nav-link.active{
    border: unset;
    color: white!important;
    border-bottom: solid 1px #0ce8ae!important;
}

.auth-img{
  background-image: url('../../../public//images/volga_smayl.png');
    background-size: contain;
   background-repeat: no-repeat;
  background-position: center;
}
.authmodal-block .card-body{
    padding: unset!important;
}
.logo-auth{
    width: 160px;
}
</style>
 