<template>
    <div id="app">
        <div class="mx-auto main-container px-0">
           <div class="row m-0 justify-content-center">
                <div class="col-sm-12 col-lg-12 col-xl-10 px-0 mw-xl-80">
                    <the-header></the-header>
                    <div class="content-container" :class="[isGamePage ? 'gameBg' : '']">
                        <router-view></router-view>
                    </div>
                    <the-footer v-if="$route.path !== '/chat'"></the-footer>
                </div>
           </div>
        </div>
        <FlashMessages></FlashMessages>
        <popup></popup>
        <AliveGamePopup></AliveGamePopup>
    </div>
</template>

<script>
    import TheHeader from '@/components/TheHeader'
    import TheFooter from '@/components/TheFooter'
    import Popup from '@/components/Popup'
    import FlashMessages from '@/components/FlashMessages'
    import AliveGamePopup from "./components/games/AliveGamePopup";

    export default {
        name: 'app',
        data() {
          return{
              currentPath : '',
          }
        },
        components: {
            TheHeader,
            TheFooter,
            FlashMessages,
            Popup,
            AliveGamePopup,
        },
        computed: {
            isGamePage() {
                return this.$route.name === 'TheGame'
            },
        },
        methods: {
            isMobile() {
                return this.$_config.environment.mobile
            },
        },
        watch: {
            $route() {
              const collapseBtn = document.getElementById('navbar-toggler')
              const collapseState = collapseBtn.getAttribute('aria-expanded')
              if (collapseState === 'true') {
                collapseBtn.click()
              }
                this.currentPath = this.$route.name;

                let chatBlock = document.getElementById("tawkchat-container");
                if (chatBlock) {
                    if (this.isGamePage && this.isMobile()) {
                        chatBlock.style.display = "none"
                    } else {
                        chatBlock.style.display = "block"
                    }
                }

            }
        }
    }
</script>

