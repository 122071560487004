<template>
    <b-modal id="BonusModal" size="lg"
             :hide-footer="true"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             class="p-0 modal"
    >
        <div class="row">
             <div class="col-12">
                <span class="bonus-text">
                    <span class="title d-block mb-3">{{ title }}</span>
                    <p class="text" v-html="text"></p>
                </span>
             </div>
        </div>

    </b-modal>
</template>

<script>
    export default {
        name: 'BonusModal',
        props:{       
            title: {
                type: String,
                default: ''
            },

            text: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style scoped>
.modal{
  margin-top: 5%;
}
.modal .modal-content {
  background: #FFFFFF!important;
  padding: 14px 7px;
}
.btn-submit{
  width: 162px;
  margin-top: 25px;
}
.mb-24 {
  margin-bottom: 24px;
}

.bonus-text{
   font-weight: 700;
   font-size: 20px;
   line-height: 30px;
   color: #FFFFFF;
   text-align: center;
}
.bonus-text .title{
  font-size: 20px;
}
.bonus-text .text{
  font-size: 16px;
}
@media only screen and (max-width: 860px) {
  .modal{
  --bs-modal-width: 90%;
}
.bonus-text{
   font-size: 14px;
   line-height: 21px;
}
}
</style>
