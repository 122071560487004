<template>
    <div class="terms-container mb-5 px-0 col-12 mx-auto" v-html="terms" v-if="terms"></div>

    <div class="terms-container mb-5 main-bg-light row w-100 mx-0 py-5" v-else>
        <div class="row justify-content-center my-5 w-100">
            <i class="fa fa-spinner fa-spin fa-fw my-5"></i>
        </div>
    </div>
</template>

<script>
  export default {
    computed: {
      terms() {
        return this.$store.getters['getTerms']
      }
    }
  }
</script>
<style>
.terms-container div .purple-area,
.terms-container div .light-purple-area,
.terms-container .first-container{
  background-image: none;
  background-color: #09235f!important;
}

.terms-highlight-color{
  color: aquamarine;
}
</style>


