<template>
    <div class="chat-container col-12 mt-3 mb-4">
        <iframe src="https://tawk.to/chat/63e34c4e4742512879121ee3/1goodbfcm" title="volga-chat"></iframe>
    </div>
</template>

<script>
    export default {
        name: 'Chat',

    }
</script>

<style scoped>
.chat-container{
    height: 90vh;
}
iframe{
    width: 100%;
    height: 100%;
}
</style>
