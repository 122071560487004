<template>
    <b-modal id="ForgotPasswordModal" size="lg"
             :hide-footer="true"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             class="p-0 modal"
    >
    <div class="row mx-0">
        <div class="col-12 mt-3">
            <b-tabs class="tab pass-recover-tab px-0 mt-3">
                <b-tab class="tab-item" active>
                    <template v-slot:title>
                        <img class="icon mr-1 mr-lg-3 mb-1" :src="emailIcon" alt="recover via email">
                        <span>Email</span>
                    </template>
                    <recover-by-email></recover-by-email>
                </b-tab>
                <b-tab class="tab-item" v-if="mainSettings && mainSettings.phoneActionsMethod ==='call'">
                    <template v-slot:title>
                        <img class="icon mr-1 mr-lg-3" :src="smsIcon" alt="recover via call">
                        <span>Телефон</span>
                    </template>
                    <recover-by-call></recover-by-call>
                </b-tab>

                <b-tab class="tab-item" v-else>
                    <template v-slot:title>
                        <img class="icon mr-1 mr-lg-3" :src="smsIcon" alt="recover via sms">
                        <span>SMS</span>
                    </template>
                    <recover-by-sms></recover-by-sms>
                    </b-tab>
            </b-tabs>
        </div>
        </div>

    </b-modal>
</template>
<script>
    import RecoverByEmail from '@/components/user/passRecover/RecoverViaEmail'
    import RecoverBySms from '@/components/user/passRecover/RecoverViaSms'
    import RecoverByCall from '@/components/user/passRecover/RecoverViaCall'

    export default {
        name: 'ForgotPasswordModal',
        data() {
            return {
                emailIcon: require('@/../public/images/icons/message.svg'),
                smsIcon: require('@/../public/images/icons/message_phone.svg')
            }
        },
        components: {
            RecoverByEmail,
            RecoverBySms,
            RecoverByCall
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            mainSettings () {
                return this.$store.getters.getMainSettings
            }
        },
        watch: {
            userData(newValue) {
                if (newValue) {
                    this.$router.push({name: 'home'})
                }
            }
        },
        mounted() {
            if (this.userData) {
                this.$router.push({name: 'home'})
            }
        }
    }
</script>

<style>
    .pass-recover-tab .nav-tabs {
        border: none !important;
        justify-content: center;
        background-color: #051949;
        overflow: hidden;
    }

    .pass-recover-tab .nav-tabs .nav-item .nav-link {
        border: none;
        outline: none;
        color: white;
    }
    .pass-recover-tab .nav-tabs .nav-item{
        width: 50%;
    }
    .pass-recover-tab .nav-tabs .nav-item .nav-link.active {
        background-color: #0a37a1!important;
        color: white;
    }
    .tab-pane {
        outline: none !important;
    }
</style>

<style scoped>
    .icon{
        width: 20px;
        height: 20px;
    }
</style>