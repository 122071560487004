<template>
    <div class="row recover-by-email-container mx-0">

        <b-form class="col-12 col-md-11 my-3 mx-auto auth-form px-0" @submit.prevent="Restore" autocomplete="off">

            <b-row class="w-100 mx-auto">
                <alert :message="response"></alert>
                <div class="clearfix"></div>
            </b-row>

            <div class="row mx-0">
                <div class="col-12 px-0">
                    <input class="form-control" required v-model="restoreEmail.email" aria-label="email">
                    <div class="placeholder">E-mail адрес <span>*</span></div>
                </div>
            </div>

            <div class="row mx-0 mt-4">
                <div class="col-4 px-0">
                    <captcha ref="pass_change_email_captcha" :captcha_key="'password_restore'" class="w-100"></captcha>
                </div>
                <div class="col-8">
                    <b-form-input v-model="restoreEmail.password_restore_captcha" required class="w-100" aria-label="captcha"></b-form-input>
                </div>
                <div class="col-12 mt-3 px-0">
                    <button class="btn pink-btn recover-button w-100" type="submit" :disabled="isSubmitting">
                        Отправить
                    </button>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>
    import Captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        components: {
            Captcha,
            Alert
        },
        data() {
            return {
                response: null,
                isSubmitting: false,
                restoreEmail: {
                    email: '',
                    password_restore_captcha: ''
                }
            }
        },
        methods: {
            Restore() {
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/restorePasswordRequest/', {
                        email: this.restoreEmail.email,
                        password_restore_captcha: this.restoreEmail.password_restore_captcha
                    },
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.isSubmitting = false
                    this.response = data
                    this.restoreEmail.password_restore_captcha = ''
                    this.$refs.pass_change_email_captcha.updateCaptcha()
                }).catch((err) => {
                    if (err) {
                        this.isSubmitting = false
                        this.response = {status: 'error', msg: err.response.data.errors}
                    }
                })
            }
        }
    }
</script>

