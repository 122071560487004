<template>
    <div class="withdraw-container pt-2 pt-lg-4 px-0 col-12 mx-auto">
  
      <div class="row mx-0 mt-4 justify-content-center">
        <button class="path-item mt-2">
          <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
        </button>
        <span class="path-icon mx-2"> » </span>
        <button class="path-item mt-2">История транзакций</button>
      </div>
  
      <div class="row mx-0 mt-2 justify-content-center">
        <h1 class="text-white mx-2">История транзакций</h1>
      </div>
        <div class="row mx-0 mb-5">
            <div class="col-12 col-md-9 col-lg-12 mx-auto px-0 pt-3">
                <inner-menu :activePage="'history'"></inner-menu>
                <div class="row mx-0 py-3 withdraw-methods-container">
                     <div class="col-12">
                        <History :histories="withdrawHistory"/>
                    </div> 
                </div>    
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import InnerMenu from '@/components/InnerMenu'
  import History from '@/components/payment/History'

  export default {
        name: 'WithdrawHistory',
        components: {
            InnerMenu,
            History
        },
        data(){
            return{
              activeHistory: null,
            }
        },
        computed: {
            withdrawHistory() {
              return this.$store.getters['getWithdrawHistory']
            },
        },

        mounted(){
            this.$store.dispatch('getWithdrawHistory')  
        }

    }
  </script>
  
  
  