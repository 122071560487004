<template>
    <div class="deposit-container pt-2 pt-lg-4 px-0 col-12 mx-auto">

        <div class="row mx-0 mt-4 justify-content-center">
            <button class="path-item mt-2">
                <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
            </button>
            <span class="path-icon mx-2"> » </span>
            <button class="path-item mt-2">Депозит</button>
        </div>

        <div class="row mx-0 mt-2 justify-content-center">
            <h1 class="text-white mx-2">Депозит</h1>
        </div>

        <div class="row mx-0 mb-5">
            <div class="col-12 col-md-11 col-lg-12 mx-auto px-0 pt-3">
                <inner-menu :activePage="'deposit'"></inner-menu>

                <div class="row mx-0 py-3 deposit-methods-container">
                    <div class="col-12 col-lg-9 px-2 px-lg-0 py-0 py-lg-4 mx-auto">

                        <div class="row my-2 mx-0 justify-content-between" v-if="depositMethods">
                            <div class="col-12 col-md-4 col-lg-4 col-xl-3 mx-auto mt-3 px-2 d-table"
                                 v-for="method in depositMethods" :key="method.id"
                                 v-lazy-container="{ selector: 'img',  loading: require('@/../public/images/icons/game-images-loader.svg') }">
                                <div class="deposit-method paymethod-label w-100 d-flex text-center  cursor-pointer vertical-middle p-1"
                                     v-on:click="selectMethod(method)"
                                     :class="[method.id == selectedMethod.id ? 'selected' : '']">
                                    <img :src="getPictureUrl(method.picture)" :data-src="getPictureUrl(method.picture)"
                                         :alt="method.name" class="w-50 mh-100">
                                      <div  class="min-amount-label w-50 d-flex justify-content-end align-items-center">
                                        <span class="control-label">
                                            Мин. {{ method.min_amount }} руб. <br />
                                            Макс. {{ method.max_amount }} руб. <br />
                                        </span>
                                      </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="row justify-content-center my-3 w-100">
                            <i class="fa fa-spinner fa-spin fa-fw my-5"></i>
                        </div>

                    </div>
                </div>
                <div class="row mx-0 py-3 make-deposit-container" v-if="userData">
                  <div class="row w-100 mt-3" v-if="amountAlert">
                    <span class="alert-amount">
                        Уважаемый клиент, пожалуйста, будьте внимательны, сумма будет изменена. Проверьте сумму на странице оплаты.
                    </span>
                  </div>
                    <b-form class="deposit-form payment-form w-100 px-1 px-lg-3 mt-5" @submit="payInFormSubmit" method="POST"
                            id="deposit-form"
                            :action="$_config.payInUrl">

                        <div class="row mx-0">
                            <div class="clearfix"></div>
                            <Alert :message="formResponse"></Alert>
                        </div>
                        <div class="row mx-0" v-if="selectedMethod && selectedMethod.id">
                            <div class="col-12 col-md-6 col-lg-6 col-xl-3">
                                <input class="form-control" v-model="sum"
                                       type="number" required id="depositAmount" ref="depositAmount"
                                       name="payin_amount" aria-label="deposit-amount"
                                       autocomplete="off">
                                <div class="placeholder">Сумма в рублях <span>*</span></div>
                                <input type="hidden" v-model="selectedMethod.id" name="method">
                                <input type="hidden" v-model="selectedMethod.module" name="selected_system">
                            </div>

                            <div class="col-12 col-md-6 col-lg-6 col-xl-5 py-2 my-3 my-md-0">
                                <div class="row mx-0">
                                    <span class="text-white">Итого к оплате {{selectedMethod.name ? ', через ' + selectedMethod.name + ': ' : ''}} </span>
                                    <span class="text-light-pink"> {{sum ? sum : 0}} Руб</span>
                                </div>
                                <div class="row mx-0">
                                   <span class="text-white">
                                        Минимальное пополнение за раз
                                        <span class="text-light-pink">{{selectedMethod.min_amount}} Руб</span>.
                                        Максимальное пополнение за раз {{selectedMethod.max_amount}} руб.
                                   </span>
                                </div>
                            </div>

                            <div class="col-6 col-xl-2 mx-auto   px-0">
                                <a class="btn purple-btn h-auto w-100 py-3"
                                        @click.prevent="$root.$emit('bv::show::modal','ActivatePromocode')">
                                    Есть промо-код?
                                </a>
                            </div>

                            <div class="col-6 col-xl-2 mx-auto  pr-0">
                                <button class="btn pink-btn h-auto w-100 py-3" type="submit">
                                    Пополнить
                                </button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
            <div class="col-12 col-md-9 col-lg-12 mx-auto coins-bg mb-3"></div>
        </div>
        <activate-promocode></activate-promocode>
    </div>
</template>

<script>
  import ActivatePromocode from '@/components/payment/ActivatePromocode'
  import Alert from '@/components/Alert'
  import InnerMenu from '@/components/InnerMenu'

  export default {
    name: 'Deposit',
    data() {
      return {
        sum: '',
        amountAlert:false,
        selectedMethod: {
          id: null,
          module: '',
          name: '',
          min_amount: null,
          max_amount: null
        },
        formResponse: null
      }
    },
    components: {
      Alert,
      ActivatePromocode,
      InnerMenu
    },
    computed: {
      userData() {
        return this.$store.getters.userData
      },
      depositMethods() {
        return this.$store.getters.getAvilDepositMethods
      }
    },
    methods: {
      getPictureUrl(url) {
        return this.$_config.baseUrl + url
      },
      selectMethod(selected) {
        if (!this.userData) {
          this.selectedMethod.id = null
          this.selectedMethod.module = ''
          this.selectedMethod.name = ''
          this.selectedMethod.min_amount = null
          this.selectedMethod.max_amount = null
          this.$router.push({name: 'home'})
          this.$bvModal.show('AuthModal')
        }
        this.amountAlert = false;
        this.selectedMethod.name = selected.name
        this.selectedMethod.id = selected.id
        this.selectedMethod.module = selected.method_module
        this.selectedMethod.min_amount = selected.min_amount
        this.selectedMethod.max_amount = selected.max_amount
        if(selected.method_module == 'adwin'){
            this.amountAlert = true;
        }

        setTimeout(() => {
          if (document.getElementById("depositAmount") !== null) {
            document.getElementById("depositAmount").focus();
          }
        }, 500)

      },
      payInFormSubmit(e) {
        if (!this.userData) {
          e.preventDefault()
          this.formResponse = {status: 'error', msg: "Unauthorized"}
          setTimeout(() => {
            this.$router.push({name: 'home'})
            this.$bvModal.show('AuthModal')
          }, 500)
          return
        }
        let payinAmount = parseFloat(this.$refs.depositAmount.value) || 0
        if (!payinAmount > 0) {
          e.preventDefault()
          this.formResponse = {status: 'error', msg: "Пожалуйста, укажите правильую сумму"}
          return false
        }
        if (!this.selectedMethod.id || !this.selectedMethod.module) {
          e.preventDefault()
          this.formResponse = {status: 'error', msg: "Пожалуйста выберите метод депозита"}
          return false
        }
        return true
      }
    }
  }
</script>

<style scoped>
    .paymethod-label {
        background-color: #fefefe;
        border: 3px solid #ffffff;
        border-radius: 5px;
        height: 90px;
    }
    .paymethod-label img {
        object-fit: contain;
    }
    .alert-amount{
     width: 100%;
     text-align: center;
     color: #ff0000;
     font-size: 17px;
    }
    .min-amount-label{
      font-size: 13px;
      font-weight: 700;
    }
    .deposit-methods-container {
        background-color: #09235f;
    }
    .make-deposit-container {
        background-color: #051949;
    }
    .selected {
        border: 3px solid #0ce8ae;
    }
</style>
