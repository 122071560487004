import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Config from '@/config'

Vue.use(Config)
Vue.use(Vuex)
Vue.use(VueAxios, axios)

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.withCredentials = true

export default new Vuex.Store({
  state: {
    games: [],
    searchV2:[],
    userData: null,
    messages: null,
    flashMessages: null,
    avilDepositMethods: null,
    avilWithdrawMethods: null,
    depositHistory:null,
    withdrawHistory:null,
    winners: null,
    wheelStats: null,
    aliveGames: null,
    gameWinners: null,
    tipData:{},
    socialLinks:{},
    statistics:{},
    balance: 0,
    userDataFetched: false,
    mainSettings: {},
    onHoldTransfers: null,
    onHoldTransfersAmount: null,
    userIdentityData : null,
    clientsLevel: null,
    uDepSum: null,
    terms: null
  },

  actions: {
    fetchApiData(context) {
      axios.get(Config.baseUrl + '/Api/apiData', {crossdomain: true})
        .then((resp) => {
          const apiData = resp.data.data.combinedData
          context.commit('setMainSettings', apiData.mainSettings)
          context.commit('setAliveGames', apiData.aliveGames)
          context.commit('setFlashMessages', apiData.flashMessages)
          context.commit('setMessages', apiData.messages)
          context.commit('setClientsLevels', apiData.levels)
          context.commit('setuDepSum', apiData.uDepSum)
          context.commit('setWheelStats', apiData.wheelStats)
          context.commit('setWinnersList', apiData.winners)
          context.commit('setWithdrawMethods', apiData.withdrawMethods)
          context.commit('setDepositMethods', apiData.availableMethods)
          context.commit('setTerms', apiData.terms)
          context.commit('setTipData', apiData.tipData)
          context.commit('setSocialLinks', apiData.socialLinks)
        }).catch(err => {
        console.log(err.message)
      })
    },
    fetchUserData(context, update = false) {
       return new Promise((resolve) => {
        axios.get(
          Config.baseUrl + '/Api/getUserInfo/' + update, {
            crossdomain: true
          }
        ).then(({data}) => {
          if (data.data) {
            let response = data.data
            let uData = null
            if (response.id) {
              uData = response
              window.UserInfo = {'id': response.id, 'login': response.login}
            }
            context.commit('setUserData', uData)
            resolve(data.data)
          }
          context.commit('setUserDataFetched')
        })
      })
    },
    fetchGames(context) {
      axios.get(Config.baseUrl + '/Api/gamesListv2', {crossdomain: true})
          .then((resp) => {
            const answer = resp.data
            if (answer.status && answer.status === 'ok') {
              context.commit('setGames', answer.data)
              context.commit('setProviders', answer.data)
              // Cache.add('games', answer.data)
            }
          }).catch(err => {
        console.log(err.message)
      })
    },

   async fetchGamesByProvidersv2(context, provider) {
      try {
       await axios.post(
            Config.baseUrl + '/Api/gamesListByProvidersv2',
            {'provider': provider}
        ).then((resp) => {
          const answer = resp.data
          if(answer.status == "ok") {
            context.commit('setGamesv2', answer.data)
          }
        }).catch(err => {
          console.log(err.message)
        })
      } catch (err) {
        console.log(err.message)
      }
    },
    removeUserData(context) {
      context.commit('removeUserData')
    },
    fetchMessages(context) {
      try {
        axios.get(Config.baseUrl + '/Api/getMessages')
          .then((resp) => {
            context.commit('setMessages', resp.data.data)
          }).catch(err => {
          console.log(err.message)
        })
      } catch (err) {
        console.log(err.message)
      }
    },
    getFlashMessages(context) {
      axios.get(
        Config.baseUrl + '/Api/getFlashMessages'
      ).then(({data}) => {
        if (data.status === 'ok') {
          if (data.data) {
            context.commit('setFlashMessages', data.data)
          }
        }
      })
    },
    getWithdrawHistory(context) {
      axios.get(
        Config.baseUrl + '/Api/withdrawHistory'
      ).then(({data}) => {
        if (data.status === 'ok') {
          if (data.data) {
            context.commit('setWithdrawHistory', data.data.lastWithdraw)
          }
        }
      })
    },
    getDepositHistory(context) {
      axios.get(
        Config.baseUrl + '/Api/depositHistory'
      ).then(({data}) => {
        if (data.status === 'ok') {
          if (data.data) {
            context.commit('setDepositHistory', data.data.lastDeposit)

          }
        }
      })
    },
    unsetFlashMessages(context) {
      axios.get(Config.baseUrl + '/Api/unsetFlashMessages'
      ).then(() => {
        context.commit('setFlashMessages', null)
      })
    },
    getWheelStats(context) {
      try {
        axios.get(Config.baseUrl + '/Api/getWheelStats')
          .then((resp) => {
            context.commit('setWheelStats', resp.data.data)
          }).catch(err => {
          console.log(err.message)
        })
      } catch (err) {
        console.log(err.message)
      }
    },
    dispatchUserBalance(context, balance) {
      context.commit('setUserBalance', balance)
    },
    checkForAliveGame(context) {
      axios.get(Config.baseUrl + '/Api/checkForAliveGame', {crossdomain: true})
        .then((resp) => {
          context.commit('setAliveGames', resp.data.data.aliveGames)
        })
        .catch(err => {
          console.log(err.message)
        })
    },
    getMainSettings(context) {
      axios.get(
        Config.baseUrl + '/Api/getMainSettings', {
          crossdomain: true
        }
      ).then(({data}) => {
        if (data.data) {
          let response = data.data
          context.commit('setMainSettings', response)
        }
      })
    },
    fetchOnHoldTransfers (context) {
      try {
        axios.get(Config.baseUrl + '/Api/getOnHoldTransactions')
          .then((resp) => {
            if (resp.data.data && resp.data.data.onHoldTransfers) {
              context.commit('setOnHoldTransactions', resp.data.data.onHoldTransfers)
            }
          }).catch(err => {
          console.log(err.message)
        })
      } catch (err) {
        console.log(err.message)
      }
    },
    fetchOnHoldTransfersAmount (context) {
      return new Promise((resolve) => {
        axios.get(
          Config.baseUrl + '/Api/getOnHoldTransactions/onlyAmount',
          {
            crossdomain: true
          }
        ).then(({ data }) => {
          if (data.data) {
            let response = data.data
            context.commit('setOnHoldTransactionsAmount', response.onHoldTransfersAmount)
            resolve(data.data)
          }
        }) .catch(err => {
          console.log(err)

        })
      })
    },
    fetchUserIdentityData (context) {
      try {
        axios.get(Config.baseUrl + '/Api/getUserIdentityData')
          .then((resp) => {
            context.commit('setUserIdentityData', resp.data.data.identityData)
          }).catch(err => {
          console.log(err.message)
        })
      } catch (err) {
        console.log(err.message)
      }
    },
    fetchStatistics(context) {
      try {
        axios.get(Config.baseUrl + '/Api/Statistics')
        .then(({data}) => {
          if (data.status === 'ok') {
            if (data.data) {
              context.commit('setStatistics', data.data)
            }
          }
        })
      } catch (err) {
        console.log(err.message)
      }
    },
    fetchSearchData(context,data) {
      try {
         axios.post(
             Config.baseUrl + '/Api/searchGamesv2',
             `q=${data}`
         ).then((resp) => {
           const answer = resp.data
           if(answer.status == "ok") {
            context.commit('setSearchV2', answer)
           }
         }).catch(err => {
           console.log(err.message)
         })
       } catch (err) {
         console.log(err.message)
       }
    },
  },
  mutations: {
    setGames (state, data) {
      state.games = data.games
    },
    setGamesv2(state, data) {
      state.games[data.provider].games = data.games;
    },
    setProviders (state, data) {
      state.providers = data.providers
    },
    setSearchV2(state, data) {
      state.searchV2 = data.data.games
    },
    setClientsLevels (state ,levels) {
      Vue.set(state, 'clientsLevel', levels)
    },
    setuDepSum (state, uDepSum) {
      Vue.set(state, 'uDepSum', uDepSum)
    },
    setUserData(state, user) {
      state.userData = user
    },
    setUserDataFetched(state) {
      state.userDataFetched = true
    },
    setStatistics(state, params){
      state.statistics = params.statistics
    },
    removeUserData(state) {
      state.userData = null
    },
    setMessages(state, msgs) {
      state.messages = msgs
    },
    setFlashMessages(state, params) {
      state.flashMessages = params
    },
    setDepositMethods(state, methods) {
      state.avilDepositMethods = methods
    },
    setWithdrawMethods(state, withdrawMethods) {
      state.avilWithdrawMethods = {
        regular: [],
        vip: []
      };
      for(var i = 0; i < withdrawMethods.length; i++){
        if (withdrawMethods[i].is_vip == 0) {
          state.avilWithdrawMethods.regular.push(withdrawMethods[i]);
        } else {
          state.avilWithdrawMethods.vip.push(withdrawMethods[i]);
        }
      }
    },
    setDepositHistory(state, depositHistory) {
      state.depositHistory = depositHistory
    },
    setWithdrawHistory(state, withdrawHistory) {
      state.withdrawHistory = withdrawHistory
    },
    setTerms(state, terms) {
      state.terms = terms
    },
    setWinnersList(state, winners) {
      state.winners = winners
    },
    setWheelStats(state, stats) {
      state.wheelStats = stats
    },
    setUserBalance(state, balance) {
      state.balance = balance
    },
    setAliveGames(state, aliveGames) {
      if (aliveGames) {
        state.aliveGames = aliveGames
      }
    },
    setTipData (state, history) {
      state.tipData = history
    },
    setSocialLinks (state, socialLinks) {
      state.socialLinks = socialLinks
    },
    setMainSettings(state, data) {
      state.mainSettings = data
    },
    setOnHoldTransactions(state, data) {
      state.onHoldTransfers = data
    },
    setOnHoldTransactionsAmount(state, data) {
      state.onHoldTransfersAmount = data
    },
    setUserIdentityData(state, identityData) {
      state.userIdentityData = identityData
    }
  },
  getters: {
    userDataFetched(state) {
      return state.userDataFetched
    },
    games(state) {
      return state.games
    },
    userData(state) {
      if (state.userData) {
        return state.userData
      }
      return null
    },
    getMessages(state) {
      try {
        return state.messages
      } catch (err) {
        console.log(err.message)
      }
    },
    getFlashMessages(state) {
      return state.flashMessages
    },
    getUnreadMessages(state, getters) {
      try {
        let msgs = []
        if (getters['getMessages'] != null) {
          Object.values(getters['getMessages']).forEach((msg) => {
            if (!msg.viewed) {
              msgs.push(msg)
            }
          })
        }
        return msgs
      } catch (err) {
        console.log(err.message)
      }
    },
    getAvilDepositMethods(state) {
      try {
        return state.avilDepositMethods
      } catch (err) {
        console.log(err.message)
      }
    },
    getDepositHistory(state) {
      try {
        return state.depositHistory
      } catch (err) {
        console.log(err.message)
      }
    },
    getAvilWithdrawMethods(state) {
      try {
        return state.avilWithdrawMethods
      } catch (err) {
        console.log(err.message)
      }
    },
    getWithdrawHistory(state) {
      try {
        return state.withdrawHistory
      } catch (err) {
        console.log(err.message)
      }
    },
    getWinnersList(state) {
      return state.winners
    },
    getWheelStats(state) {
      try {
        if (state.wheelStats !== null) {
          let progressPercent = (state.wheelStats.profitBorder / 100) * state.wheelStats.lastMonthDeposit
          state.wheelStats['progressPercent'] = parseInt(progressPercent)
        }
        return state.wheelStats
      } catch (e) {
        console.log(e.messages)
      }
    },
    aliveGames(state) {
      return state.aliveGames
    },
    getBalance(state) {
      return state.balance
    },
    getMainSettings(state) {
      return state.mainSettings
    },
    getOnHoldTransactions (state) {
      return state.onHoldTransfers
    },
    getOnHoldTransactionsAmount (state) {
      return state.onHoldTransfersAmount
    },
    identityData (state) {
      return state.userIdentityData
    },
    clientsLevel (state) {
      return state.clientsLevel
    },
    uDepSum (state) {
      return state.uDepSum
    },
    getTerms (state) {
      return state.terms
    },
    getTipData (state) {
      return state.tipData
    },
    getSocialLinks (state) {
      return state.socialLinks
    },
    getStatistics (state) {
      return state.statistics
    },
    getSearchV2 (state) {
      return state.searchV2
    }
  }
})
