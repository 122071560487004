<template>
    <div class="row mx-0">
        <div class="col-12 px-0">
            <img :src="captchaUrl" class="captcha-img w-100" alt="captcha">
        </div>
        <div class="col-12 mx-auto px-0">
            <a @click.prevent="updateCaptcha" class="mt-1 w-100 h-auto btn btn-secondary btn-sm border-0 text-white refresh-btn">
                <i class="far fa-redo"></i>
                <span class="refresh-text"> обновить</span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['captcha_key'],
        name: 'Captcha',
        data () {
            return {
                random: Math.random()
            }
        },
        computed: {
            captchaUrl () {
                return `${this.$_config.captchaUrl}/${this.captcha_key}?${this.random}`
            }
        },
        methods: {
            updateCaptcha () {
                this.random = Math.random()
            }
        }
    }
</script>

<style scoped>
    .refresh-btn{
        background-color: #0ce8ae !important;
    }
</style>
