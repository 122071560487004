<template>
     <div class="mobile-footer">
        <router-link :to="{ name: 'home' }" class="link" @click="scrollData" :class="{ active: isActive == '/' }">
            <img src="/images/icons/slot.svg" >
        </router-link>
        <router-link :to="{ name: 'bonuses' }" class="link" @click="scrollData" :class="{ active: isActive == '/Bonuses' }">
            <img src="/images/icons/bonus.svg" >
        </router-link>
        <router-link :to="{ name: 'TheGame', params: {gameName:'KronosWheel', gameId:1, provider: 'WheelOfFortune'}}" class="link" @click="scrollData" v-if="userData"  :class="{ active: isActive == '/Game/KronosWheel' }">
            <img src="/images/icons/koleco.svg">
        </router-link>
        <a  class="link" @click="login" v-else >
            <img src="/images/icons/koleco.svg">
        </a>
        <router-link :to="{ name: 'chat' }" class="link" :class="{ active: isActive == '/chat' }">
          <img src="/images/icons/chat.svg">
        </router-link>
        <router-link :to="{ name: 'deposit' }" class="link" @click="scrollData" :class="{ active: isActive == '/deposit' }">
          <img src="/images/icons/deposit.svg" >
        </router-link>
        <router-link :to="{ name: 'withdraw' }" class="link" @click="scrollData" :class="{ active: isActive == '/Withdraw' }">
          <img src="/images/icons/withdraw.svg" >
        </router-link>
     </div>
 </template>
 
 <script>

 
 export default {
   name: 'MobileFooter',
   
   computed: {
      userData() {
        return this.$store.getters.userData
      },
      isActive() {
        return this.$route.path;
     },
    },

    methods:{
      scrollData(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      login(){
        this.$router.push({name: 'home'})
        return  this.$bvModal.show('AuthModal')
      }
    }

 }
 </script>
 
 <style scoped>
 .mobile-footer{
   width: 100%;
   height: 45px;
   position: fixed;
   bottom: 0;
   background-image: url("../../../public/images/backgrounds/volga_bg.webp") !important;
   background-size: cover !important;
   box-shadow: 0px 0px 20px 0px #2451cd;
   display: flex;
   justify-content: space-around;
   align-items: center;
   padding: 0 20px;

 }

 .link img{
   width: 25px;
   height: 20px;
 }

 .active{
   width: 30px;
   height: 30px;
   border:1px solid #0ce8ae;
   border-radius: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
  
 }

 .active img{
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(134deg) brightness(118%) contrast(119%)
 
}

 </style>
 