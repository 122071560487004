<template>
    <b-modal id="flashMessagesModal" v-model="canShowMessages" v-if="flashMessages" size="lg" :hide-footer="true">
        <h3 class="text-white text-center my-4">Системное сообщение</h3>
        <div class="row mx-0 message-text-container text-white px-3 py-3 mb-3" v-for="(msg, idx) in flashMessages"
             :key="idx">
            <span v-html="msg"></span>
        </div>

        <div class="col-12 px-0 text-center">
            <div class="text-white d-inline-block">
                <button class="btn markAsReadBtn text-white" @click.prevent="unsetFlashMessages">
                    Отметить как прочитанное
                </button>
            </div>
        </div>

    </b-modal>
</template>

<script>
    export default {
        name: 'FlashMessages',
        computed: {
            flashMessages() {
                return this.$store.getters['getFlashMessages']
            },
            canShowMessages() {
                if (this.flashMessages) {
                    return true
                }
                return false
            }
        },
        methods: {
            unsetFlashMessages() {
                this.$store.dispatch('unsetFlashMessages')
                this.$root.$emit('bv::hide::modal', 'flashMessagesModal')
            }
        }
    }
</script>

<style scoped>
    .message-text-container {
        background-color: #190b25;
        border-radius: 5px;
    }

    .markAsReadBtn {
        right: 0;
        bottom: 0;
        box-shadow: none !important;
    }

    .markAsReadBtn:hover {
        color: #ffee98 !important;
    }
</style>
