<template>
    <div class="row mx-0 w-100">
        <div class="row w-100 mx-0 mt-2 mt-lg-4 justify-content-center">
            <button class="path-item mt-2">
                <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
            </button>
            <span class="path-icon mx-2"> » </span>
            <button class="path-item mt-2">Статистика игр</button>
        </div>

        <div class="row mx-0 mt-2 statistic-title justify-content-center w-100">
            <h1 class="text-white mx-2">Статистика игр</h1>
        </div>

        <b-card class="border-0 w-100 mt-3 mb-5">  
            <b-tabs card class="change-statistic-tab px-0">
                <b-tab title="Ретро">
                  <StatisticsBlock :statistics="statistics.retro" :type="'retro'"/>
                </b-tab>

                <b-tab title="Популярные игры">
                    <StatisticsBlock :statistics="statistics.popular" :type="'popular'"/>
                </b-tab>

                <b-tab title="Большие выигрыши">
                    <StatisticsBlock :statistics="statistics.big_wins" :type="'big_wins'"/>
                </b-tab>

                <b-tab title="Вчерашние крупные выигрыши">
                    <StatisticsBlock :statistics="statistics.yesterdays_big_wins"  :type="'yesterdays_big_wins'"/>
                </b-tab>

            </b-tabs>
        </b-card>

    </div> 
  </template>
  
  
  <script>
    import StatisticsBlock from "./elements/StatisticsBlock";

    export default {
        name: 'Statistics',
        components: {
            StatisticsBlock,
        },

        computed: {
            statistics() {
                return this.$store.getters['getStatistics']
            },
        },

        mounted() {
            this.$store.dispatch('fetchStatistics')
        }
    }
  </script>
<style>
    .change-statistic-tab .nav-tabs .nav-item {
        width: 25%;
        border: none !important;
        margin: 0;
    }
    .card {
        background-color: transparent!important;
    }
    @media only screen and (max-width: 767px) {
        .change-statistic-tab .nav-tabs .nav-item {
           width: 50%;
        }
    }
</style>
  
  <style scoped>
  .statistic-title{
    margin-bottom: 85px;
  }

  .change-statistic-tab .card-header {
        background-color: transparent!important;
        border: none;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    .change-statistic-tab .nav-tabs {
        border: none !important;
        font-size: 20px;
        justify-content: center;
    }


    .change-statistic-tab .nav-tabs .nav-item .nav-link {
        color: white !important;
        background-color: #190b25;
        border: none;
    }

    .change-pass-tab .nav-tabs .nav-item .nav-link.active {
        background-color: #261336;
    }

    .change-statistic-tab .nav-tabs .nav-item .nav-link {
        border-radius: 0;
        text-align: center;
        outline: none !important;
        font-size: 16px;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .change-statistic-tab .card-body {
        padding: 0;
    }

  </style>
  
  