<template>
    <div class="login-container col-12 mt-3">
        <div class="pb-5">
            <div class="row">
                <div class="col-12">
                    <b-form class="auth-form" @submit.prevent="Login" autocomplete="off">
                        <div class="row">
                            <div class="col-12  px-lg-3">
                                <input class="form-control" required type="text" v-model="payload.login"
                                       aria-label="user-login">
                                <div class="placeholder">Логин <span>*</span></div>
                            </div>
                            <div class="col-12 mt-3 px-lg-3">
                                <input class="form-control" required type="password" v-model="payload.password"
                                       aria-label="user-password">
                                <div class="placeholder">Пароль <span>*</span></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <alert :message="response"></alert>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12 px-1 px-lg-3">
                                <button class="btn auth-button pink-btn w-100 border-0" @click.prevent="Login"
                                        :disabled="isSubmitting">
                                    Войти
                                </button>
                            </div>
                            <div class="col-12 mt-3">
                                <a class="recover-btn  w-100 border-0" @click="forgotPassword">
                                    Забыли пароль?
                                </a>
                            </div>
                        </div>

                    </b-form>
                </div>
            </div>
        </div>
        <ForgotPasswordModal />
    </div>
</template>

<script>
    import Alert from '@/components/Alert'
    import ForgotPasswordModal from '../modals/ForgotPasswordModal'

    export default {
        name: 'login',
        props:['activeTab'],
        components: {
            Alert,
            ForgotPasswordModal
        },
        data() {
            return {
                response: null,
                isSubmitting: false,
                payload: {
                    login: '',
                    password: ''
                }
            }
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            }
        },
        methods: {
            forgotPassword(){
                this.$bvModal.show('ForgotPasswordModal')
            },
          Login() {
            this.response = {status: 'loading'}
            this.isSubmitting = true

            if (this.payload.login === '' || this.payload.password === '') {
              this.isSubmitting = false
              this.response = {status: 'error', msg: 'Заполните все необходимые поля'}
              return
            }

            this.axios.post(
              this.$_config.baseUrl + '/Api/Login', {
                authorize: this.payload
              }
            ).then((userData) => {
              let data = userData.data
              this.isSubmitting = false
              this.response = data
              if (data.status === 'ok') {
                this.$store.dispatch('fetchUserData')
                this.$store.dispatch('fetchApiData')
                this.response = {status: 'ok', msg: 'Добро пожаловать'}
                this.$store.dispatch('fetchMessages')
                this.$store.dispatch('getWheelStats')
                this.$store.dispatch('getMainSettings')
              } else {
                this.payload.password = ''
              }
            }).catch((err) => {
              if (err) {
                this.isSubmitting = false
                this.response = {status: 'error', msg: err.response.data.errors}
              }
            })
          }
        },
        mounted() {
            if (this.userData) {
                this.$router.push({name: 'home'})
            }
        },
        watch: {
            userData() {
                if (this.userData) {
                    this.$router.push({path: '/'})
                }
            },
            activeTab(newVal){
                if(newVal){
                    this.response = null;
                }
            }
        }
    }
</script>

<style scoped>
.form-control{
    background: #071637;
    border: 2px solid transparent;
    border-radius: 8px;
    color: #fff;
    height: 44px;
    outline: none !important;
    padding: 0 12px;
    touch-action: manipulation;
    width: 100%;
}
.login-container{
    min-height: 350px;
}
.auth-form{
    padding-top: 50px;
}
.recover-btn{
    color: #828f9a;
    cursor: pointer;
}
.recover-btn:hover{
    color: #fff;
}
</style>
