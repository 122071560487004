<template>
    <b-modal id="ActivatePromocode" size="lg" v-model="show" :hide-footer="true" class="promocode-modal" @close="closeModal">
        <div class="row mx-0">
            <h3 class="text-white mx-auto">Активация промокода</h3>
        </div>

        <b-form class="form-horizontal payment-form w-100" @submit.prevent="activatePromocode" autocomplete="off">
            <b-row class="w-100 mx-auto">
                <alert :message="response"></alert>
                <div class="clearfix"></div>
            </b-row>

            <div class="row mx-0">
                <div class="col-12 px-0">
                    <b-form-input v-model="payload.promocode" required></b-form-input>
                    <div class="placeholder">Промокод <span>*</span></div>
                </div>
            </div>

            <div class="row mx-0 mt-4">
                <div class="col-4 col-lg-3 px-0">
                    <captcha ref="captcha_promocode_activation" :captcha_key="'promocode_activation'"
                             class="w-100"></captcha>
                </div>
                <div class="col-8 col-lg-3 pr-0 pr-lg-2">
                    <b-form-input v-model="payload.captcha" required class="my-1"></b-form-input>
                </div>

                <div class="col-8 col-lg-6 px-0 mt-3 mt-lg-0 mx-auto">
                    <button class="btn purple-btn border-0 w-100 py-3" type="submit" name="submit"
                            :disabled="isSubmitting">
                        Активировать
                    </button>
                </div>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
    import Alert from '@/components/Alert'
    import Captcha from '@/components/Captcha'

    export default {
        name: 'ActivatePromocode',
        data() {
            return {
                show: false,
                response: null,
                isSubmitting: false,
                payload: {
                    promocode: '',
                    captcha: ''
                }
            }
        },
        components: {
            Captcha,
            Alert
        },
        methods: {
            closeModal() {
                this.payload.promocode = ''
                this.payload.captcha = ''
                this.response = null
                this.$root.$emit('bv::hide::modal', 'ActivatePromocode')
            },
            activatePromocode() {
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/ActivateMyPromocode/',
                    `promocode=${this.payload.promocode}&promocode_activation_captcha=${this.payload.captcha}`,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.response = data
                    this.isSubmitting = false
                    this.payload.promocode = ''
                    this.payload.captcha = ''
                    if (data.status !== 'error') {
                        setTimeout(() => {
                            this.show = false
                        }, 3000)
                    }
                    this.$refs.captcha_promocode_activation.updateCaptcha()

                }).catch(err => {
                    if (err) {
                        this.$refs.captcha_promocode_activation.updateCaptcha()
                        this.response = {status: 'error', msg: err.msg}
                        this.isSubmitting = false
                    }
                })
            }
        }
    }
</script>
