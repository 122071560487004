<template>
    <div class="row recover-by-sms-container mx-0">
        <b-form class="col-12 col-md-11 my-3 mx-auto auth-form px-0" @submit.prevent="Restore" autocomplete="off">

            <b-row class="w-100 mx-auto">
                <alert :message="response"></alert>
                <div class="clearfix"></div>
            </b-row>

            <div class="row mx-0">
                <div class="col-12 px-0">
                    <the-mask :mask="['+#(###)-###-##-##', '+#(###)-###-##-###']" class="form-control" v-model="form.phone"
                              aria-label="phone-number"
                              required type="text"></the-mask>
                    <div class="placeholder">Номер телефона <span>*</span></div>
                </div>
            </div>

            <div class="row mx-0 mt-4" v-if="!show">
                <div class="col-4 px-0">
                    <captcha ref="password_change_sms" :captcha_key="'password_change_sms'"></captcha>
                </div>
                <div class="col-8">
                    <b-form-input v-model="form.captcha" required class="w-100" aria-label="captcha"></b-form-input>
                </div>
                <div class="col-12 mt-3 px-0">
                    <button class="btn pink-btn recover-button w-100" type="submit" :disabled="isSubmitting">
                        Звонить
                    </button>
                </div>
            </div>

            <div v-else>
                <div class="row mx-0 mt-4">
                    <div class="col-12 px-0">
                        <b-form-input v-model="form.call_code" type="text" required aria-label="call-code"></b-form-input>
                        <div class="placeholder">Последние 4 цифры номера  с которого поступил звонок<span>*</span></div>
                    </div>
                    <div class="col-4">
                        <b-form-input v-model="form.new_password" required type="password" aria-label="password"></b-form-input>
                        <div class="placeholder">Пароль <span>*</span></div>
                    </div>
                    <div class="col-4 px-0">
                        <b-form-input v-model="form.new_password_confirm" type="password" required aria-label="re-password"></b-form-input>
                        <div class="placeholder">Пароль еще раз <span>*</span></div>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-4 px-0">
                        <captcha ref="password_change_sms" :captcha_key="'password_change_sms'"></captcha>
                    </div>
                    <div class="col-8">
                        <b-form-input v-model="form.captcha" required class="w-100" aria-label="captcha"></b-form-input>
                    </div>
                    <div class="col-12 mt-3 px-0">
                        <button class="btn pink-btn recover-button w-100" type="submit" :disabled="isSubmitting">
                            Отправить
                        </button>
                    </div>
                </div>
            </div>
        </b-form>
    </div>
</template>

<script>
    import Captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        data() {
            return {
                response: null,
                isSubmitting: false,
                form: {
                    phone: '',
                    captcha: '',
                    call_code: '',
                    new_password: '',
                    new_password_confirm: ''
                },
                show: false
            }
        },
        components: {
            Captcha,
            Alert
        },
        methods: {
            Restore() {
                this.response = {status: 'loading'}
                this.isSubmitting = true

                if (this.show) {
                    let sendPayload = {
                        phone: this.form.phone,
                        call_code: this.form.call_code,
                        new_password: this.form.new_password,
                        password_restore_captcha: this.form.captcha,
                        new_password_confirm: this.form.new_password_confirm
                    }

                    let queryString = this.$_config.makeQueryStringFromObject(sendPayload)

                    this.axios.post(
                        this.$_config.baseUrl + '/Api/restorePasswordAfterCall', queryString,
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    ).then(({data}) => {
                        this.isSubmitting = false
                        this.response = data
                        if (data.status === 'ok') {
                            this.show = false
                            this.form.captcha = ''
                            this.$refs.password_change_sms.updateCaptcha()
                            setTimeout(() => this.$router.push({name: 'home'}),  this.$bvModal.show('AuthModal'), 3000)
                        }
                    }).catch((err) => {
                        if (err) {
                            this.isSubmitting = false
                            this.form.captcha = ''
                            this.$refs.password_change_sms.updateCaptcha()
                            this.response = {status: 'error', msg: err.response.data.errors}
                        }
                    })
                } else {
                    this.axios.post(
                        this.$_config.baseUrl + '/Api/callForRestorePassword',
                        `phone=${this.form.phone}&captcha=${this.form.captcha}`,
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    ).then(({data}) => {
                        this.isSubmitting = false
                        this.response = data
                        if (data.status === 'ok') {
                            this.show = true
                        }
                        this.form.captcha = ''
                        this.$refs.password_change_sms.updateCaptcha()
                    }).catch((err) => {
                        if (err) {
                            this.isSubmitting = false
                            this.$refs.password_change_sms.updateCaptcha()
                            this.form.captcha = ''
                            this.response = {status: 'error', msg: err.response.data.errors}
                        }
                    })
                }
            }
        }
    }
</script>
