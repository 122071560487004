<template>
    <div class="col-12 px-0 py-5 mx-auto game-load-block" ref="gameContainer">
        <div class="row mx-0 pb-5">
            <div class="col-12 col-md-11 mx-auto px-0 game-cross-block">
                <div class="px-0 game-response-container text-center" id="game-response-container">
                    <iframe class="gameLaunchIframe"
                            :src="gameLaunchUrl()"
                            allowfullscreen
                            frameborder="0"
                            marginheight="0"
                    >
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  /*eslint no-debugger: "error"*/
  import {scroller} from 'vue-scrollto/src/scrollTo'
  import Config from '@/config'

  const secondScrollTo = scroller()

  export default {
    name: 'TheGame',
    props: ['game', 'provider', 'gameName', 'gameId', 'restoring', 'isLive'],
    data() {
      return {
        isLandscape: false,
        response: {status: 'loading'}
      }
    },
    watch: {
      isLandscape() {
        this.isLandscape = screen.height < screen.width
      },
      restoring() {
        if (this.restoring) {
          this.$bvModal.hide('HasAliveGame')
        }
      }
    },
    computed: {
      userData() {
        return this.$store.getters['userData']
      },
      isMobile() {
        return this.$_config.environment.mobile
      },
    },
    methods: {
      gameLaunchUrl() {
        return Config.baseUrl + '/Api/LoadGame/Poker'
      },
      containerSize() {
        try {
          let availWidth = document.querySelector('.game-cross-block').offsetWidth
          return {
            width: availWidth,
            height: availWidth / 1.8
          }
        } catch (err) {
          console.log(err.message)
        }
      },
      setOriantationOnChangeEvent() {
        window.addEventListener('resize', () => {
          setTimeout(() => {
            this.isLandscape = window.innerWidth > window.innerHeight
          }, 20)
        })
      },
    },
    mounted() {
      if (this.isMobile) {
        window.location.href = this.gameLaunchUrl()
        return false
      }
      this.setOriantationOnChangeEvent();

      if (!this.userData) {
        this.$router.push({name: 'home'})
               return  this.$bvModal.show('AuthModal')
      }
      setTimeout(() => {
        secondScrollTo('iframe')
      }, 600)
    }
  }
</script>

<style scoped>
    .gameLaunchIframe {
        width: 100%;
        min-height: 100vh;
    }
    .game-load-block {
        min-height: fit-content !important;
        background-repeat: no-repeat;
        height: fit-content;
    }
</style>
