<template>
    <div>
        <b-container class="bv-example-row mt-5">
            <b-row>
                <b-col class="statistic-table-top" v-if="type === 'popular'">Название игры</b-col>
                <b-col class="statistic-table-top" v-else>Сумма выигрыша</b-col>
                <b-col class="statistic-table-top" v-if="type === 'popular'">Сыграно</b-col>
                <b-col class="statistic-table-top" v-else>Пользователь</b-col>
                <b-col class="statistic-table-top">Игра</b-col>
            </b-row>
            <b-row class="statistics-data-block mt-3"  v-for="(statistic, idx) in statistics" :key="idx">
                <b-col class="statistic-table" v-if="type === 'popular'">{{ statistic.game_name }}</b-col>
                <b-col class="statistic-table" v-else>{{ statistic.amount }} руб</b-col>
                <b-col class="statistic-table" v-if="type === 'popular'">{{ statistic.game_count }} раз</b-col>
                <b-col class="statistic-table" v-else>{{ statistic.user }}</b-col>
                <b-col class="statistic-table">
                    <img :src="statisticImg(statistic)">
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>




export default {
  name: 'StatisticsBlock',
  props:['statistics','type'],

  methods:{
    statisticImg(statistic){
        let url = statistic.preview ?? statistic.picture;
        return this.$_config.storageUrl + url;
    }
  }
  
}
</script>

<style scoped>
.statistic-table-top{
    min-height: 30px;
    background-color: #08b287;
    text-align: center;
    margin: 0 5px;
    color: aliceblue;
}
.statistic-table{
    height:60px;
    color: rgb(37, 37, 37);
    background-color: aliceblue;
    display: flex;
    align-items: center;
    justify-content: center;
}
.statistic-table img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
@media only screen and (max-width: 380px) {
    .statistic-table-top{
        font-size: 13px;
        margin: 0 3px;
    }
    .statistic-table{
        font-size: 13px;
    }
}
</style>