<template>
    <b-modal id="wheelInfoModal" size="lg" :hide-footer="true">
        <h3 class="text-white text-center">Колесо фортуны</h3>
        <div class="row mx-0 my-3">
            <span class="text-white text-center">
                Колесо фортуны активируется при пополнении от 5000 руб. в течении месяца (месяц считается до текущего дня).
            </span>

            <span class="text-white text-center mt-4">
                К примеру: для доступа к Колесу Фортуны сегодня, сумма Ваших пополнений за {{setDates.monthAgo}} до
                {{setDates.now}} должна быть больше 5000 руб.
            </span>
        </div>
        <div class="row mx-0 my-4">
            <router-link :to="{ name: 'deposit' }" class="btn account-btn mx-auto px-5 pt-2">
                Депозит
            </router-link>
        </div>
    </b-modal>
</template>

<script>
    export default {
        computed: {
            setDates() {
                let date = new Date()
                let monthAgo = new Date(date)
                monthAgo.setDate(monthAgo.getDate() - 30)
                date = new Date(date).toLocaleString('ru-RU', {hour12: false})
                monthAgo = new Date(monthAgo).toLocaleString('ru-RU', {hour12: false})

                return {now: date, monthAgo: monthAgo}
            }
        }
    }
</script>
