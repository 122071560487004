<template>
    <div class="home-container">
        <slider></slider>
        <div class="main-content mt-3 col-12 px-0 ">
            <div class="row mx-0 social mt-md-5">

                <div class="col-12 mt-3 col-md-6">
                   <a :href="socialLinks.tg_group" target="_blank" v-if="socialLinks.tg_group">
                      <div class="w-100 social-block tg-block"></div>
                   </a>
                  <div class="w-100 social-block tg-block" v-else></div>
                </div>

                <div class="col-12 mt-3 social-wk-block col-md-6">
                    <a :href="socialLinks.vk_group" target="_blank" v-if="socialLinks.vk_group">
                        <div class="w-100 social-block wk-block"></div>
                    </a>
                  <div class="w-100 social-block wk-block" v-else></div>
                </div>

            </div>
             <game-list></game-list>
        </div>
    </div>
</template>

<script>
    import Slider from '@/components/home/Slider'
    import GameList from '@/components/games/GameList'

    export default {
        name: 'Home',
        components: {
            Slider,
            GameList,
        },
        data(){
            return{
                tgImg:require('@/../public/images/backgrounds/tg.png'),
                wkImg:require('@/../public/images/backgrounds/wk.png'),
            }
        },
      computed: {
        socialLinks() {
          return this.$store.getters.getSocialLinks
        },
      }
    }
</script>
<style scoped>
    .social-block{
        height: 140px;
        border-radius: 20px;
        box-shadow: 0 0 50px 0 rgba(51, 124, 255, 1);
    }
    .tg-block{
        background-image: url('../../public/images/backgrounds/tg.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .wk-block{
        background-image: url('../../public/images/backgrounds/wk.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    @media only screen and (max-width: 1126px) {
        .social-block{
          height: 100px;
        }
    }

    @media only screen and (max-width: 767px) {
        .social-wk-block{
            margin-top: 30px!important;
        }
        .social-block{
          height: 140px;
        }
    }
    @media only screen and (max-width: 486px) {
        .social-block{
          height: 100px;
        }
    }
    @media only screen and (max-width: 406px) {
        .social-block{
          height: 80px;
        }
    }
</style>
