<template>
    <div class="col-12 mt-2 px-0">
        <div v-if="alertMessage"
             v-bind:class="'alert alert-'+alertType" role="alert"
             v-html="alertMessage">
        </div>
        <div v-else-if="isLoading">
            <div class="alert alert-primary">
                <div class="col-12 resp-loader loading">
                    <i class="fa fa-spinner fa-spin fa-fw"></i>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'Alert',
        props: ['message'],
        computed: {
            alertType() {
                return this.message.status === 'ok' ? 'success' : 'danger'
            },
            alertMessage() {
                if (this.message) {
                    if (Array.isArray(this.message.msg)) {
                        let msg = ''
                        for (let i = 0; i < this.message.msg.length; i++) {
                            msg += this.message.msg[i]
                            msg += '<br>'
                        }
                        return msg
                    }
                    return this.message.msg
                }
                return null
            },
            isLoading() {
                if (!this.message) {
                    return false
                }
                return this.message.status === 'loading'
            }
        }
    }
</script>

<style>
    .alert {
        border: none !important;
        text-align: center;
        margin: 10px auto;
    }

    .alert-danger {
        background-color: #ff3505!important;
        font-size: 16px;
        color: white !important;
    }

    .alert-primary {
        background-color: rgba(204, 229, 255, 0.07) !important;
        font-size: 26px;
    }

    .alert-success {
        font-size: 16px;
        background-color: rgba(103, 202, 103, 0.966) !important;
        color: white !important;
    }
</style>
