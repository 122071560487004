<template>
    <div class="account-container pt-2 pt-lg-4 px-0 col-12 mx-auto" v-if="userData">
        <div class="row mx-0 mt-4 justify-content-center">
            <button class="path-item mt-2">
                <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
            </button>
            <span class="path-icon mx-2"> » </span>
            <button class="path-item mt-2">Аккаунт</button>
        </div>

        <div class="row mx-0 mt-2 justify-content-center">
            <h1 class="text-white mx-2">Аккаунт</h1>
        </div>

        <div class="row mx-0 mb-5">
            <div class="col-12 col-md-9 col-lg-12 mx-auto px-0 pt-3">
                <inner-menu :activePage="'account'"></inner-menu>
                <div class="row mx-0" v-if="userData">
                    <b-list-group class="account-list w-100">
                        <b-list-group-item>
                            <div class="col-12 col-md-9 col-lg-8 mx-auto px-0">
                                <div class="row mx-0">
                                    <div class="col-6 text-left"><span class="text-white">{{userData.login}}</span>
                                    </div>
                                    <div class="col-6 text-right">
                                        <button class="btn pink-btn px-4 border-0" @click.prevent="logout">
                                            Выйти
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item>
                            <div class="col-12 col-md-9 col-lg-8 mx-auto px-0">
                                <div class="row mx-0">
                                    <div class="col-6 text-left"><span class="text-white">Ваше имя </span></div>
                                    <div class="col-6 text-right" v-if="userData.name">
                                        <span class="text-white">
                                            {{userData.name}}
                                        </span>
                                    </div>

                                    <div class="col-6 text-right px-0 px-md-2" v-else>
                                        <form class="w-100 border-0">
                                            <Alert :message="updateNameResponse"></Alert>
                                            <fieldset class="form-group row m-0">
                                                <div class="col-12 col-lg-8 px-0 pr-lg-2">

                                                    <input type="text" placeholder="Ваше имя *" required
                                                           aria-required="true"
                                                           class="form-control here name-input"
                                                           v-model="usersName">
                                                </div>
                                                <div class="col-12 col-lg-4 px-0 mt-2 mt-lg-0 text-lg-center">
                                                    <b-button class="btn pink-btn px-4 border-0"
                                                              @click.stop.prevent="updateUsersName">
                                                        Подтвердить
                                                    </b-button>
                                                </div>
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item>
                            <div class="col-12 col-md-9 col-lg-8 mx-auto px-0">
                                <div class="row mx-0">
                                    <div class="col-6 text-left"><span class="text-white">Баланс</span></div>
                                    <div class="col-6 text-right">
                                           <span class="text-white">
                                              {{userData.balance}} {{userData.currency_name}}
                                           </span>
                                        <router-link :to="{ name: 'deposit' }"
                                                     class="px-0 ml-2 txt-purple font-weight-bold">
                                            Пополнить
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </b-list-group-item>

                        <b-list-group-item>
                            <div class="col-12 col-md-9 col-lg-8 mx-auto px-0">
                                <div class="row mx-0">
                                    <div class="col-6 text-left"><span class="text-white">Номер телефона </span></div>
                                    <div class="col-6 text-right">
                                           <span class="text-white">
                                               {{userData.phone}}
                                           </span>
                                        <b-button @click.prevent="confirmPhone" v-if="!userData.phone_confirmed"
                                                  class="btn btn-confirm txt-purple font-weight-bold border-0">
                                            Подтвердить
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-list-group-item>

                        <b-list-group-item>
                            <div class="col-12 col-md-9 col-lg-8 mx-auto px-0">
                                <div class="row mx-0">
                                    <div class="col-6 text-left"><span class="text-white">Email</span></div>
                                    <div class="col-6 text-right">
                                           <span class="text-white">
                                             {{userData.email}}
                                           </span>
                                        <b-button @click.prevent="confirmEmail" v-if="userData.email_confirmed == 0"
                                                  class="btn btn-confirm txt-purple font-weight-bold border-0">
                                            Подтвердить
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-list-group-item>

                        <b-list-group-item>
                            <div class="col-12 col-md-9 col-lg-8 mx-auto px-0">
                                <div class="row mx-0">
                                    <div class="col-6 text-left"><span class="text-white">Пароль</span></div>
                                    <div class="col-6 text-right">
                                        <b-button @click.prevent="changePassword" class="btn pink-btn px-4 border-0">
                                            Изменить <span class="d-none d-xl-inline">пароль</span>
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-list-group-item>

                        <b-list-group-item>
                            <div class="col-12 col-md-9 col-lg-8 mx-auto px-0">
                                <div class="row mx-0">
                                    <div class="col-6 text-left"><span class="text-white">Верификация аккаунта</span>
                                    </div>
                                    <div class="col-6 text-right">
                                        <p class="d-inline-block my-0 text-white mr-3" v-if="accountIdentifyStatus">
                                            <span v-html="accountIdentifyStatus"></span>
                                            <br>
                                            <small v-if="accountIdentifyStatusDesc" v-html="accountIdentifyStatusDesc"
                                                   class="text-white"></small>
                                        </p>
                                        <b-button
                                                v-if="showIdentificationButton"
                                                @click.prevent="confirmPassport"
                                                class="btn pink-btn px-4 border-0">
                                            Пройти верификацию
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-list-group-item>

                        <b-list-group-item>
                            <div class="col-12 col-md-9 col-lg-8 mx-auto px-0">
                                <div class="row mx-0">
                                    <div class="col-6 text-left">
                                        <span class="text-white">Колесо фортуны</span>
                                        <b-button @click.prevent="wheelInfo" class="btn info-btn px-2 border-0">
                                            <img :src="infoIcon" alt="wheel info">
                                        </b-button>
                                    </div>
                                    <div class="col-6">
                                        <div class="progress float-right mt-1">
                                            <div class="progress-bar progress-bar-striped"
                                                 v-bind:style="{ width: wheelStats ? wheelStats.progressPercent : 0 + '%' }"></div>
                                            <div class="progress-text font-weight-bold position-absolute">
                                                <span class="text-white">{{wheelStats ? wheelStats.lastMonthDeposit : 0}} / {{wheelStats ? wheelStats.profitBorder : 0}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </div>
            </div>
            <div class="col-12 col-md-9 mx-auto coins-bg mb-3"></div>
        </div>
        <ConfirmPhoneViaCall :userData="userData"
                             v-if="mainSettings && mainSettings.phoneActionsMethod === 'call'"></ConfirmPhoneViaCall>
        <ConfirmPhone :userData="userData" v-else></ConfirmPhone>
        <ConfirmEmail :userData="userData"></ConfirmEmail>
        <ChangePassword :userData="userData"></ChangePassword>
        <WheelInfoModal></WheelInfoModal>
        <ConfirmAccount :user-data="userData" v-if="userData.verified !== 1"
                        @changed="updateState()"></ConfirmAccount>
    </div>
</template>

<script>
  import ConfirmPhone from '@/components/user/profile/ConfirmPhone'
  import ConfirmPhoneViaCall from '@/components/user/profile/ConfirmPhoneViaCall'
  import ConfirmEmail from '@/components/user/profile/ConfirmEmail'
  import ChangePassword from '@/components/user/profile/ChangePassword'
  import WheelInfoModal from '@/components/user/profile/WheelInfoModal'
  import InnerMenu from '@/components/InnerMenu'
  import ConfirmAccount from '@/components/user/profile/ConfirmAccount'
  import Alert from '@/components/Alert'

  export default {
    components: {
      ConfirmPhone,
      ConfirmEmail,
      ChangePassword,
      WheelInfoModal,
      InnerMenu,
      ConfirmPhoneViaCall,
      ConfirmAccount,
      Alert
    },
    data() {
      return {
        infoIcon: require('@/../public/images/icons/info.png'),
        identityData: null,
        accountIdentifyStatus: null,
        accountIdentifyStatusDesc: null,
        showIdentificationButton: false,
        updateNameResponse: null,
        usersName: ''
      }
    },
    updated() {
      this.identityData = this.$store.getters.identityData
      // this.setAccountIdentifyStatus()
    },
    async created() {
      await this.$store.dispatch('fetchUserIdentityData')
    },
    computed: {
      userData() {
        return this.$store.getters.userData
      },
      wheelStats() {
        return this.$store.getters.getWheelStats
      },
      mainSettings() {
        return this.$store.getters.getMainSettings
      },
      userIdentityData() {
        return this.$store.getters.identityData
      }
    },
    methods: {
      setAccountIdentifyStatus() {
        if (!this.userData) {
          return
        }
        let accountVerified = this.userData.verified
        let identifyConfirmedStatus = false

        if (this.identityData) {
          identifyConfirmedStatus = this.identityData.approved
        }
        this.showIdentificationButton = false
        this.accountIdentifyStatus = null
        this.accountIdentifyStatusDesc = null

        switch (accountVerified) {
          case 0:
            this.showIdentificationButton = true
            this.accountIdentifyStatus = '<span class="text-danger">Аккаунт не верифицирован</span>'
            if (identifyConfirmedStatus === 2) {
              const reason = this.identityData.reject_reason
              this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
            }
            if (identifyConfirmedStatus === 0) {
              this.showIdentificationButton = false
              this.accountIdentifyStatus = '<span class="text-warning">Данные в обработке</span>'
            }
            break;
          case -1:
            this.showIdentificationButton = true
            this.accountIdentifyStatus = '<strong class="text-danger">Необхадимо пройти верификацию !</strong>'

            if (identifyConfirmedStatus === 0) {
              this.showIdentificationButton = false
              this.accountIdentifyStatus = '<span class="text-warning">Данные в обработке</span>'
            }
            if (identifyConfirmedStatus === 2) {
              const reason = this.identityData.reject_reason
              this.showIdentificationButton = true
              this.accountIdentifyStatus = '<span class="text-warning">Получено запрос на верификацию</span>'
              this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
            }
            break
          case 1:
            this.accountIdentifyStatus = '<span class="text-success">Аккаунт верифицирован</span>'
            break
        }
      },
      updateState() {
        this.identityData = this.$store.getters.identityData
        this.setAccountIdentifyStatus()
      },
      logout() {
        this.axios.get(
          this.$_config.baseUrl + '/Api/logout'
        ).then(() => {
          this.$store.dispatch('removeUserData')
          this.$router.push({name: 'home'})
        }).catch(err => {
          console.log(err)
        })
      },
      changePassword() {
        this.$bvModal.show('changePasswordModal')
      },
      confirmPhone() {
        this.$bvModal.show('confirmPhoneModal')
      },
      confirmEmail() {
        this.$bvModal.show('confirmEmailModal')
      },
      wheelInfo() {
        this.$bvModal.show('wheelInfoModal')
      },
      confirmPassport() {
        this.$root.$emit('bv::show::modal', 'confirmPassportModal')
      },
      updateUsersName() {
        this.updateNameResponse = {status: 'loading'}
        let queryString = `name=${this.usersName}`

        this.axios.post(
          this.$_config.baseUrl + '/Api/updateUserName/', queryString,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        ).then(({data}) => {
          this.updateNameResponse = data

          console.log(data)
          if (data.status !== "error") {
            this.$store.dispatch('fetchUserData')
            setTimeout(() => {
              this.updateNameResponse = null
            }, 5000)
          }
        }).catch(() => {
          this.updateNameResponse = {
            status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
          }
        })
      }
    },
    mounted() {
      // this.$store.dispatch('getWheelStats')
      setTimeout(() => {
        if (!this.userData) {
          this.$router.push({name: 'home'})
        }
      }, 2500)
      this.identityData = this.$store.getters.identityData
      this.setAccountIdentifyStatus()
    },
    watch: {
      identityData: {
        handler: function (newValue) {
          if (newValue) {
            this.updateState()
          }
        },
        deep: true

      },
      userData: {
        handler: function (data) {
          if (data) {
            this.setAccountIdentifyStatus()
          }
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
    .name-input:focus {
        box-shadow: 0 0 0 0.2rem #09235f;
    }

    .info-btn,
    .btn-confirm {
        background: transparent !important;
        box-shadow: none !important;
    }

    .txt-purple {
        color: #0ce8ae !important;
    }

    .account-list .list-group-item {
        border: none;
        border-radius: unset;
    }

    .account-list .list-group-item:nth-child(odd) {
        background-color: #09235f;
    }

    .account-list .list-group-item:nth-child(even) {
        background-color: #051949;
    }

    .progress-text {
        left: 50%;
        top: 7px;
        font-size: 14px;
    }

    .progress {
        background-color: #09235f;
        height: 25px;
        width: 80%;
    }

    .progress-bar {
        background-color: #0ce8ae;
    }

    .progress-bar-striped {
        background-image: linear-gradient(45deg, #0ce8ae 25%, transparent 25%, transparent 50%, #0ce8ae 50%, #0ce8ae 75%, transparent 75%, transparent);
        background-size: 3rem 3rem;
    }

</style>
