<template>
    <div class="row mx-0 w-100">
        <div class="row w-100 mx-0 mt-2 mt-lg-4 justify-content-center">
            <button class="path-item mt-2">
                <router-link :to="{ name: 'home' }" class="text-white"> Главная</router-link>
            </button>
            <span class="path-icon mx-2"> » </span>
            <button class="path-item mt-2">Бонусы</button>
        </div>

        <div class="row mx-0 mt-2 bonus-title justify-content-center w-100">
            <h1 class="text-white mx-2">Бонусы</h1>
        </div>

     <div class="col-12 col-sm-6  bonuses-block" >  
        <div class="bonus">
          <img :src=images.whell>
        </div>
        <div class="bonus">
          <div class="bonus-btn" >
              <button class="pink-btn more-details" @click="whell">ПОДРОБНЕЕ</button> 
          </div>
        </div>
     </div>

     <div class="col-12 col-sm-6 bonuses-block">  
        <div class="bonus">
          <img :src=images.cashback>
        </div>
        <div class="bonus">
          <div class="bonus-btn" >
              <button class="pink-btn more-details" @click="cashBack">ПОДРОБНЕЕ</button> 
          </div>
        </div>
     </div>

     <div class="col-12 col-sm-6 bonuses-block" >  
        <div class="bonus">
          <img :src=images.x2>
        </div>
        <div class="bonus">
          <div class="bonus-btn" >
              <button class="pink-btn more-details" @click="x2">ПОДРОБНЕЕ</button> 
          </div>
        </div>
     </div>

     <div class="col-12 col-sm-6 bonuses-block">  
        <div class="bonus">
          <img :src=images.tg>
        </div>
        <div class="bonus">
          <div class="bonus-btn" >
              <button class="pink-btn more-details" @click="telegram" v-if="socialLinks.tg_manager">ПОДРОБНЕЕ</button>
          </div>
        </div>
     </div>
     <BonusModal  :text="text" :title="title" /> 
    </div> 
  </template>
  
  
  <script>
    import BonusModal from "../components/modals/BonusModal";

  export default {
    name: 'Bonuses',
    components: {
      BonusModal,
    },

    data(){
       return{
          text:null,
          title:null,
          telegramTitle:'TELEGRAM КАНАЛ',
          x2Title:'УДВОЕНИЕ ДЕПОЗИТА',
          whellTitle:'Колесо Велеса',
          cashBackText:'Каждый день, в 9:00 по МCК возвращаем Вам 5% от Вашего проигрыша. </br></br>  Если в течение  24 часа у вас был проигрыш, то кэшбек автоматически поступит на Ваш игровой счет.',
          cashBackTitle:'Cashback',
          startDate: null,
          endDate: null,
          lastDeposit:0,
          wheelDeposit:5000,
          percentWhell:0,
          whellData:{},
          images:{
            cashback:require('@/../public/images/bonus/bonus_1.png'),
            whell:require('@/../public/images/bonus/bonus_2.png'),
            x2:require('@/../public/images/bonus/bonus_3.png'),
            tg:require('@/../public/images/bonus/bonus_4.png'),
          }
       }
    },

    methods:{
      telegram(){

         this.text = 'Подтвердив Ваш номер телефона через Telegram, Вы получите 50 бонусных рубля. Для этого Вам надо написать МЕНЕДЖЕРУ казино по  ссылке в телеграмм: <a href="'+this.socialLinks.tg_manager+'" target="_blank">'+this.socialLinks.tg_manager+'</a>. В сообщении напишите Ваш логин, и менеджер пополнит Вам 50 бонусных рублей в период времени от 5 минут до 24 часов.БОНУС действителен для активных игроков.',
         this.title = this.telegramTitle,
         this.$bvModal.show('BonusModal')
      },
      x2(){
         this.text = 'В месяц раз доступен удвоение депозита.Условия вывода удвоения: допустим Вы пополнили 500руб., то получите еще 500 бонусных рубля, получается 1000 бонусных рублей. Вывод будет с двукратном размере  от 2000 рублей и выше.Минимальная сумма пополнения - 200, a максимальная 5000 руб. Удвоение доступно для активных игроков!'
         this.title = this.x2Title,
         this.$bvModal.show('BonusModal')
      },
     async whell(){
        this.text = 'Каждый день у вас есть уникальный шанс выиграть до 1000 руб. Колесо Фортуны активируется при пополнении от' + ' ' + this.wheelDeposit + ' ' + 'руб. в течение месяца (месяц считается до текушего дня). </br></br> К примеру: для доступа к Колесу Фортуны сегодня, сумма Ваших пополнений за  ' + this.startDate + ' до ' + this.endDate + ' должна быть больше ' + this.wheelDeposit + 'руб. </br></br>  Деньги выигранные в Колесе Фортуны не выводятся! Ими можно играть, и в случае выигрыша можно вывести выигрыш.';
        this.title = this.whellTitle,
        this.$bvModal.show('BonusModal')
      },

      cashBack(){
         this.title = this.cashBackTitle,
         this.text = this.cashBackText,
         this.$bvModal.show('BonusModal')
      },

      formatDate(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return new Date(date).toLocaleDateString('ru-Ru', options);
      }
    },

    mounted() {
      const now = new Date();
      now.setMonth(now.getMonth() - 1);
      this.startDate =this.formatDate(now);
      this.endDate = this.formatDate(new Date());
    },
    computed: {
      socialLinks() {
        return this.$store.getters.getSocialLinks
      },
    }
  }
  </script>
  
  <style scoped>
  .title{
    margin: 65px;
  }
  .title h2{
     font-weight: 700;
     font-size: 3vw;
     line-height: 95px;
     color: #FFFFFF;
     text-align: center;
  }
  .more-details{
    font-weight: 700;
    border: solid 2px #0ce8ae!important ;
  }
  .bonus img{
    object-fit: none;
    width: 100%;
    height: 350px;
  }
.bonus-btn button{
    width: 200px;
    height: 40px;
}
  .bonus{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bonus-btn{
    width: 200px;
    height: 40px;
    margin: 60px 0;
  }
  .bonus-title{
    margin-bottom: 85px;
  }
  @media only screen and (max-width: 1300px) {
    .bonus img{
    width: 100%;
  }
  }
  @media only screen and (max-width: 860px) {
    .title h2{
     font-size: 16px;
  }
  .bonus img{
    width: 290px;
    height: 310px;
  }
  .bonus{
   height: 200px;
  }
  }
  </style>
  
  