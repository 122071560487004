<template>
    <div class="footer row w-100 mx-0">
        <div class="col-12 footer-menu text-center py-3">
            <div class="row mx-0 footer-logo">
                <img :src="logo" class="mx-auto" alt="VolaSlot">
            </div>

            <div class="row mx-0 justify-content-center mt-4">
                <ul class="list-unstyled">
                    <li class="d-inline-block mx-3">
                        <router-link :to="{ name: 'home' }" exact>
                            <span class="menu-item">Казино</span>
                        </router-link>
                    </li>
                    <li class="d-inline-block mx-3">
                        <router-link :to="{ name: 'deposit' }" exact>
                            <span class="menu-item">Депозит</span>
                        </router-link>
                    </li>

                    <li class="d-inline-block mx-3">
                        <router-link :to="{ name: 'withdraw' }" exact>
                            <span class="menu-item">Вывод</span>
                        </router-link>
                    </li>

                    <li class="d-inline-block mx-3">
                        <router-link :to="{ name: 'bonuses' }" exact>
                            <span class="menu-item">Бонусы</span>
                        </router-link>
                    </li>

                    <li class="d-inline-block mx-3">
                        <router-link
                                :to="{ name: 'TheGame', params: {gameName:'KronosWheel', gameId:1, provider: 'WheelOfFortune'}}"
                                exact>
                            <span class="menu-item">Колесо</span>
                        </router-link>
                    </li>

                    <li class="d-inline-block mx-3">
                        <router-link :to="{ name: 'terms' }" exact>
                            <span class="menu-item">Условия</span>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="row mx-0 justify-content-center">
                <ul class="list-unstyled">
                    <li class="d-inline-block mx-3 mt-3" v-for="(provider,index) in providers" :key="index">
                        <img :src="provider" class="footer-provider ">
                    </li>
                </ul>
            </div>

            <div class="row mx-0 justify-content-around my-4">
                <ul class="list-unstyled row w-100  mx-0 justify-content-center">

                    <li class="col-md-1 px-0"></li>

                    <li class="col-7 col-sm-4 col-md-3 col-xl-2 px-0 mb-2 mb-xl-0" v-if="socialLinks.email">
                        <div class="row mx-0">
                            <div class="col-2 px-0 footer-icon">
                                <img :src="socialIcons.email" class="social-icon icon-email mw-100" alt="email" width="35px" height="35px">
                            </div>
                            <div class="col-10 px-2 text-left">
                                <span class="text-pink font-weight-bold">Email:</span><br>
                                <span>
                                    <a class="text-white" :href="'mailto:'+socialLinks.email">{{socialLinks.email}}</a>
                                </span>
                            </div>
                        </div>
                    </li>

                    <li class="col-7 col-sm-4 col-md-3 col-xl-2 px-0 mb-2 mb-xl-0" v-if="socialLinks.vk_manager">
                        <div class="row mx-0">
                            <div class="col-2 px-0 footer-icon">
                                <img :src="socialIcons.vk" class="social-icon mw-100" alt="vk" width="35px" height="35px">
                            </div>
                            <div class="col-10 px-2 text-left">
                                <span class="text-pink font-weight-bold">ВК Менеджер:</span><br>
                                <span class="text-white">
                                    <a :href="socialLinks.vk_manager" target="_blank" rel="noreferrer"
                                       class="text-white">
                                        Связаться
                                    </a>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li class="col-7 col-sm-4 col-md-3 col-xl-2 px-0 mb-2 mb-xl-0" v-if="socialLinks.tg_manager">
                        <div class="row mx-0">
                            <div class="col-2 px-0 footer-icon">
                                <img :src="socialIcons.tg" class="social-icon mw-100" alt="telegram" width="35px" height="35px">
                            </div>
                            <div class="col-10 px-2 text-left">
                                <span class="text-pink font-weight-bold">Телеграм Менеджер:</span><br>
                                <span class="text-white">
                                    <a :href="socialLinks.tg_manager" target="_blank" rel="noreferrer"
                                       class="text-white">
                                        Связаться
                                    </a>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li class="col-7 col-sm-4 col-md-3 col-xl-2 px-0 mb-2 mb-xl-0" v-if="socialLinks.tg_group">
                        <div class="row mx-0">
                            <div class="col-2 px-0 footer-icon">
                                <img :src="socialIcons.tg" class="social-icon mw-100" alt="TG chanel" width="35px" height="35px">
                            </div>
                            <div class="col-10 px-2 text-left">
                                <span class="text-pink font-weight-bold">Телеграм Канал:</span><br>
                                <span class="text-white">
                                    <a :href="socialLinks.tg_group" class="text-white">
                                        Связаться
                                    </a>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li class="col-10 col-sm-4 col-md-3 col-xl-2 px-0 mt-3 mt-xl-0">
                        <a id="claim_here" class="btn pink-btn px-4 border-0">Написать Жалобу</a>
                    </li>

                </ul>
            </div>

            <div class="row mx-0 justify-content-center foooter-bootom-text">
                <span class="text-white copyright"> © {{getThisYear}} VolgaSlot. Все права защищены.</span>
            </div>
        </div>
        <div class="w-100 d-block d-md-none">
            <MobileFooter />
        </div>
    </div>
</template>


<script>
  import MobileFooter from "../components/elements/MobileFooter";
    export default {
        name: 'RwvFooter',
        components: {
            MobileFooter,
        },
        data() {
            return {
                logo: require('@/../public/images/logos/logo.svg'),
                providers: [
                    require('@/../public/images/logos/egt.png'),
                    require('@/../public/images/logos/igrosoft.png'),
                    require('@/../public/images/logos/microgaming.png'),
                    require('@/../public/images/logos/netent.png'),
                    require('@/../public/images/logos/wazdan.png'),
                ],
                socialIcons: {
                    email: require('@/../public/images/icons/social/email.svg'),
                    vk: require('@/../public/images/icons/social/vk.svg'),
                    tg: require('@/../public/images/icons/social/tg.svg')
                },
            }
        },
        computed: {
            getThisYear() {
                return new Date().getFullYear()
            },
            socialLinks() {
              return this.$store.getters.getSocialLinks
            }
        }
    }
</script>


<style scoped>
    .social-icon {
        object-fit: contain;
    }
    .payment-img {
        max-width: 150px;
    }

    .footer-logo img{
        width: 200px;
    }
    .footer-menu {
        border: solid 2px #0ce8ae;
        border-bottom: unset;
        border-radius: 8px;
        box-shadow: 0 0 5px 5px #067456 !important;
    }

    .payment-methods-area {
        background-color: #0f0c17;
    }

    .router-link-active .menu-item,
    .menu-item:hover {
        color: #0ce8ae;
    }
    .footer-provider{
        width: 80px;
    }

    @media only screen and (max-width: 1200px) {
        .payment-img {
            max-width: 60px;
        }

        .copyright {
            font-size: 12px;
        }
    }
    @media only screen and (max-width: 767px) {
        .foooter-bootom-text{
            margin-bottom: 50px;
        }
    }
</style>
