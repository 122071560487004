<template>
    <b-modal v-model="show"
             :hide-footer="true"
             :no-close-on-backdrop="true"
             :no-close-on-esc="true">
        <div class="popup-body row mx-0 justify-content-center">
            <h3 class="text-white">У вас новое сообщение!</h3>

          <div class="row w-100 msg-item my-2 px-0 mx-0" v-if="unreadMessages.length >= 5">
            <div class="col-12">
              <div class="row mx-0 message-text-container text-white"> </div>
              <div class="row mx-0 mt-2 justify-content-end">
                <button class="btn btn-sm markAsReadBtn text-white" @click.prevent="markAsReadAll">
                  Отметить все как прочтенное
                </button>
              </div>
              <hr class="my-2">
            </div>
          </div>


            <div class="row w-100 msg-item my-2 px-0 mx-0" v-for="(msg, idx) in unreadMessages" :key="idx">
                <div class="col-12">
                    <div class="row mx-0 message-text-container text-white" v-html="msg.message"> </div>
                    <div class="row mx-0 mt-2 justify-content-end">
                        <button class="btn btn-sm markAsReadBtn text-white" @click.prevent="markAsRead(msg.id)">
                            Отметить как прочитанное
                        </button>
                    </div>
                    <hr class="my-2">
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: 'Popup',
        computed: {
            unreadMessages() {
                return this.$store.getters['getUnreadMessages']
            },
            show() {
                return this.$store.getters['getUnreadMessages'].length ? true : false
            }
        },
        methods: {
            markAsRead(id) {
                this.axios.get(this.$_config.baseUrl + '/Api/MessageViewed/' + id).then(() => {
                    this.$store.dispatch('fetchMessages')
                }).catch(err => {
                  console.log(err)
                })
            },
            markAsReadAll() {
              this.axios.get(this.$_config.baseUrl + '/Api/MessageViewedAll').then(() => {
                this.$store.dispatch('fetchMessages')
              }).catch(err => {
                console.log(err)
              })
            }
        }
    }
</script>

<style scoped>
    hr {
        background-color: white;
    }

    .markAsReadBtn {
        box-shadow: none !important;
    }

    .markAsReadBtn:hover {
        color: #ffee98 !important;
    }
</style>
