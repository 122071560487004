<template>
    <b-modal id="confirmPhoneModal" size="lg" :hide-footer="true" v-if="userData" @close="closeModal">

        <div class="modal-title text-center mb-3">
            <span class="text-white mb-4">Подтверждение тел. номера</span><br>
            <span class="phone-number mb-4">{{userData.phone ? userData.phone : ''}}</span>
        </div>

        <form @submit.prevent="confirmPhone" class="phone-confirmation-form modal-form mt-4" autocomplete="off"
              v-if="!successedVerification">

            <Alert :message="smsSendResponse"></Alert>
            <Alert :message="phoneConfirmResponse"></Alert>

            <b-form-group v-if="!userData.phone && !confirmCodeSent">
                <div class="row mx-0">
                    <b-form-input v-model="phoneConfirmPayload.phone" required
                                  v-mask="['+#(###)-###-##-##', '+#(###)-###-##-###']"></b-form-input>
                </div>
            </b-form-group>

            <b-form-group v-if="confirmCodeSent">
                <div class="row mx-0 mb-4">
                    <span class="text-white ">Мы отправили SMS с кодом на ваш номер телефона.</span>
                </div>

                <div class="row mx-0 position-relative">
                    <b-form-input v-model="phoneConfirmPayload.sms_code" required></b-form-input>
                    <div class="placeholder">SMS-Код <span>*</span></div>
                </div>
            </b-form-group>
            <b-form-group>
                <div class="row mx-0">
                    <div class="col-4 col-lg-2 px-0">
                        <captcha ref="phone_confirm_captcha" :captcha_key="'phone_confirm_captcha'"></captcha>
                    </div>
                    <div class="col-8 col-lg-6">
                        <b-form-input v-model="phoneConfirmPayload.captcha" required></b-form-input>
                    </div>
                    <div class="col-8 col-lg-4 mx-auto mt-3 mt-lg-0 px-0">
                        <button name="submit" type="submit" class="btn pink-btn px-3 w-100"
                                v-if="!confirmCodeSent"
                                :disabled="isSmsSending" @click.prevent="sendSmsCode">
                            Отправить SMS
                        </button>

                        <button name="submit" type="submit" class="btn pink-btn px-3 w-100" v-else
                                :disabled="isFormSubmitting">
                            Подтвердить
                        </button>
                    </div>
                </div>
            </b-form-group>
        </form>
        <div class="row mx-0 my-5" v-else>
            <span class="mx-auto text-center text-white">
                Отлично! Ваш номер телефона подтверждён.
            </span>
        </div>
    </b-modal>
</template>


<script>
    import Captcha from '@/components/Captcha'
    import Alert from '@/components/Alert'

    export default {
        props: ['userData'],
        name: 'ConfirmPhone',
        data() {
            return {
                confirmCodeSent: false,
                smsSendResponse: null,
                isSmsSending: false,
                isFormSubmitting: false,
                phoneConfirmResponse: null,
                phoneConfirmPayload: {
                    phone: '',
                    captcha: '',
                    sms_code: ''
                },
                successedVerification: false
            }
        },
        components: {
            Alert,
            Captcha
        },
        methods: {
            closeModal() {
                this.smsSendResponse = null
                this.phoneConfirmResponse = null
            },
            sendSmsCode() {
                this.smsSendResponse = {status: 'loading'}
                this.isSmsSending = true
                let queryString = `phone=${this.phoneConfirmPayload.phone ? this.phoneConfirmPayload.phone : this.userData.phone}&captcha=${this.phoneConfirmPayload.captcha}`
                this.axios.post(
                    this.$_config.baseUrl + '/Api/sendConfirmationSms', queryString,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.smsSendResponse = data
                    if (data.status === 'ok') {
                        this.confirmCodeSent = true
                    }
                    this.phoneConfirmPayload.captcha = ''
                    this.isSmsSending = false
                }).catch(() => {
                    this.smsSendResponse = {
                        status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
                    }
                    this.phoneConfirmPayload.captcha = ''
                    this.isSmsSending = false
                })
                this.$refs.phone_confirm_captcha.updateCaptcha()
            },
            confirmPhone() {
                this.phoneConfirmResponse = {status: 'loading'}
                this.isFormSubmitting = true
                this.smsSendResponse = null
                let queryString = `phone_confirmation_captcha=${this.phoneConfirmPayload.captcha}&sms_code=${this.phoneConfirmPayload.sms_code}`

                this.axios.post(
                    this.$_config.baseUrl + '/Api/PhoneConfirmRequest', queryString, {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                ).then(({data}) => {
                    this.phoneConfirmResponse = data
                    if (data.status === 'ok') {
                        this.$store.dispatch('fetchUserData')
                        this.successedVerification = true
                        setTimeout(() => {
                            this.phoneConfirmResponse = null
                            this.$root.$emit('bv::hide::modal', 'confirmPhoneModal')
                        }, 5000)
                    }
                    this.phoneConfirmPayload.captcha = ''
                }).catch(() => {
                    this.phoneConfirmResponse = {status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'}
                })
                this.$refs.phone_confirm_captcha.updateCaptcha()
                this.isFormSubmitting = false
            }
        },
        mounted() {
            if (this.userData) {
                this.phoneConfirmPayload.phone = this.userData.phone
            }
        }
    }
</script>

<style scoped>
    .modal-title span {
        font-size: 30px;
        font-weight: 600;
        word-break: break-all;
    }

    .modal-title .phone-number {
        color: #0ce8ae;
    }

    @media only screen and (max-width: 767px) {
        .modal-title span {
            font-size: 22px;
            font-weight: 500;
        }
    }
</style>
