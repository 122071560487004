<template>
    <div class="forgot-pass-container py-4 px-0 col-12 col-md-10 mx-auto">

        <div class="recover-bg mx-auto pb-0 pb-lg-5">

        <div class="row mx-0 mt-4 justify-content-center">
            <button class="path-item mt-2">
                <router-link :to="{ name: 'home' }" class="text-white"> Главная </router-link>
            </button>
            <span class="path-icon mx-2"> » </span>
            <button class="path-item mt-2">Восстановить пароль</button>
        </div>

        <div class="row mx-0 mt-2 justify-content-center">
            <h1 class="text-white text-center mx-2">Восстановить пароль</h1>
        </div>

        <div class="row mx-0">
            <div class="col-12 col-md-9 col-xl-6 mx-auto px-0 py-3">

                <b-tabs class="tab pass-recover-tab px-0">
                    <b-tab class="tab-item" active>
                        <template v-slot:title>
                            <img class="icon mr-1 mr-lg-3 mb-1" :src="emailIcon" alt="recover via email">
                            <span>Email</span>
                        </template>
                        <recover-by-email></recover-by-email>
                    </b-tab>
                    <b-tab class="tab-item" v-if="mainSettings && mainSettings.phoneActionsMethod ==='call'">
                        <template v-slot:title>
                            <img class="icon mr-1 mr-lg-3" :src="smsIcon" alt="recover via call">
                            <span>Телефон</span>
                        </template>
                        <recover-by-call></recover-by-call>
                    </b-tab>

                    <b-tab class="tab-item" v-else>
                        <template v-slot:title>
                            <img class="icon mr-1 mr-lg-3" :src="smsIcon" alt="recover via sms">
                            <span>SMS</span>
                        </template>
                        <recover-by-sms></recover-by-sms>
                      </b-tab>
                </b-tabs>

            </div>
        </div>

        </div>
    </div>
</template>

<script>
    import RecoverByEmail from '@/components/user/passRecover/RecoverViaEmail'
    import RecoverBySms from '@/components/user/passRecover/RecoverViaSms'
    import RecoverByCall from '@/components/user/passRecover/RecoverViaCall'

    export default {
        data() {
            return {
                emailIcon: require('@/../public/images/icons/wallet.png'),
                smsIcon: require('@/../public/images/icons/message_phone.svg')
            }
        },
        components: {
            RecoverByEmail,
            RecoverBySms,
            RecoverByCall
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            },
            mainSettings () {
                return this.$store.getters.getMainSettings
            }
        },
        watch: {
            userData(newValue) {
                if (newValue) {
                    this.$router.push({name: 'home'})
                }
            }
        },
        mounted() {
            if (this.userData) {
                this.$router.push({name: 'home'})
            }
        }
    }
</script>

